import { APP_COLORS } from "../../styles";
import Dialog from "../common/Dialog";
import logo from '../../assets/logo.png';

export default function PropertyFullView ({item, onClose,onOpenImageGallery }) {
    return (
        <Dialog 
            style={{borderRadius:0}}
            showClose 
            onClose={onClose} >
            <div style={{
                height:'100vh',
                width:'80vw', 
                boxSizing:'border-box',
                background:APP_COLORS.META_BACKGROUND, 
                display:'flex', 
                justifyContent:'space-between'
                }}>

               <div style={{
                    flex: 1.5, 
                    overflow:'auto',
                    display:'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr)',
                    gridAutoRows: '300px',
                    gridGap:5,
                    gridAutoFlow:'dense'
                     }}>
                    {item.images && item.images.map((image,i) => (
                        <img src={image} 
                        onClick={()=>{onOpenImageGallery(i, item.images)}}    
                        style={{
                            width: '100%', 
                            height:'100%',
                        }}
                      />
                    ))}
                </div>     
                
                <div style={{
                    width:'400px', 
                    boxSizing:'border-box',
                    display:'flex', 
                    flexDirection:'column',
                     }}>
                    <div style={{ position:'sticky', top:0, }}>
                        <div style={{
                            height:'50px', 
                            borderBottom:`1px solid #ccc`,
                            display:'flex', 
                            alignItems:'center'
                            }}>
                            <div style={{
                                backgroundImage: `url(${logo})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                marginLeft:10,
                                height: '40px',
                                width: '200px',
                                }}>
                            </div>
                        </div>  
                        <div style={{
                            padding:10,
                            paddingBottom:0,
                            display:'flex',
                            flexDirection:'column',
                            gap:10,
                            }}>
                            
                            <div style={{
                                marginTop:20,
                                display:'flex', 
                                gap:10, 
                                alignItems:'flex-end'}}>
                                <div 
                                    style={{
                                        color:APP_COLORS.BUTTON_BACKGROUND,
                                        fontSize:'32px', 
                                        fontWeight:'bold'
                                        }}>${item.price}</div>
                                <div style={{paddingBottom:4}}>
                                     <b>{item.bed}</b> bed, <b>{item.bath}</b> bath,  <b>{item.area}</b> sqft
                                </div>
                                
                            </div>
                            <div style={{
                                fontSize:'18px'
                            }}>
                                    {item.address}
                            </div>
                            
                            <div style={{
                                fontSize:'18px', 
                                fontWeight:'bold',
                                paddingBottom:'10px',
                                borderBottom: `1px solid #ccc`
                                }}>Details
                            </div>
                            </div>
                            </div>
                            

                            <div style={{
                                padding:'10px 10px',
                                overflow:'scroll', 
                                textOverflow:'ellipsis', 
                                whiteSpace:'pre-wrap',
                                lineHeight:'1.3',
                                }}>
                                {item.remarks}
                            </div>
                    
                </div>
                
            </div>
        </Dialog>)
}