import React, { useState, useEffect, useRef } from 'react';
import TextField from './TextField';
import { useDispatch } from 'react-redux';
import { APP_COLORS } from '../../styles';
import Button from './Button'

const SearchContainer = ({onEnter, searchString}) => {
  const dispatch = useDispatch();
  let searchContainerRef = useRef();

//   const searchParams = useLocation().search
//   const params = new URLSearchParams(searchParams)

  let [searchedValue, setSearchedValue] = useState(searchString)

//   let searchSuggestions = useSelector(selectSearchSuggestions)
//   let _searchSuggestions = useRef()
//   _searchSuggestions.current = searchSuggestions;

//   let [cursor, setCursor] = useState(0);
//   let cursorRef = useRef(cursor);

//   useEffect(() => {
//     if (params.get('search')) {
//       setSearchedValue(params.get('search'))
//     }
//     window.addEventListener("click", clearDropdown);
//     return () => {
//       window.removeEventListener("click", clearDropdown);
//     }
//   }, [])

//   const clearDropdown = (e) => {
//     if (searchContainerRef.current && !searchContainerRef.current.contains(e?.target)) {
//       // _searchSuggestions.current = 0;
//       cursorRef.current = 0;
//       setCursor(0)
//       dispatch(fetchSearchMatches(""))
//     }
//   }

  const onEnterPressed = (text) => {
    // params.delete("search")
    // text && text.length && params.append("search", text)
    // history.push({ search: params.toString() })
    setSearchedValue(text && text.length && text)
    onEnter(text)
    // clearDropdown()
  }

  const onReset = (text) => {
    setSearchedValue('')
    onEnter('')
  }

//   const handleUpDown =
//     (keyCode) => {
//       if (keyCode === 'ArrowDown' && (cursorRef.current < _searchSuggestions.current.length)) {
//         cursorRef.current += 1
//         setCursor((prevValue) => prevValue + 1)
//         setSearchedValue(_searchSuggestions.current[cursorRef.current - 1])
//       } else if (keyCode === 'ArrowUp' && cursorRef.current > 0) {
//         cursorRef.current -= 1
//         setCursor((prevValue) => prevValue - 1)
//         setSearchedValue(_searchSuggestions.current[cursorRef.current - 1])
//       }
//     }

  return (
    <div style={{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'center',
      }}  
      ref={searchContainerRef} >
        <div>
        <TextField
          text={searchedValue}
          placeholder={'Search...'}
          onEnter={onEnterPressed}
          onTextChange={(text) => {  }}
          search = {true}
          onCancel = {onReset}
          autoFocus={false}
          // onArrowKey={handleUpDown}
          containerStyle={styles.input}
        />

      </div>
      
      
      {/* {searchSuggestions && searchSuggestions.length > 0 &&
        <SearchDropDown
          searchSuggestions={searchSuggestions}
          onDropDownClick={onEnter}
          cursor={cursor}
        />} */}
    </div>
  )
}

export default SearchContainer;

// const SearchDropDown = ({ searchSuggestions, onDropDownClick, cursor }) => {
//   let dropDown = useRef();
//   let className = 'hovercell';
  
//   // this useEffect takes care of if a class is active a mouse hovers on 
//   // it so the active class is removed and mouse hover is applied
//   useEffect(() => {
//     const removeClassActive = (e) => {
//       let elements = document.getElementsByClassName('active')
//       while (elements[0]) {
//         elements[0].classList.remove('active');
//       }
//     }
//     if (dropDown && dropDown.current) {
//       dropDown.current.addEventListener("mouseover", removeClassActive);
//     }
//     return function cleanup() {
//       dropDown.current.removeEventListener("mouseover", removeClassActive);
//     };
//   }, []);
//   return (<div
//     style={styles.searchDropdown}
//     ref={dropDown} >
//     {searchSuggestions.slice(0, 10).map((suggestion, index) => (
//       <div
//         style={styles.dropdownItem}
//         key={index}
//         onClick={() => { onDropDownClick(suggestion); }}
//         className={cursor === index + 1 ? 'active ' + className : className}
//       > {suggestion} </div>
//     ))
//     }
//   </div>)
// }

const styles = {
  input: {
    marginBottom:0,
    outline: 'none',
    color: APP_COLORS.PRIMARY_TEXT,
    width:'20vw',
  },
//   caretDown: {
//     color: '#999',
//     fontSize: 18,
//     width: 20,
//     height: 20
//   },
//   searchDropdown: {
//     zIndex: 1,
//     position: 'absolute',
//     top: '40px',
//     width: '200px',
//     background: THEME.BASE_BACKGROUND,
//     color:THEME.SECONDARY_TEXT,
//     display: 'flex',
//     flexDirection: 'column',
//     boxShadow: '0px 0px 6px 6px rgba(0, 0, 0, 0.05)',
//     padding: 5,
//     borderBottomLeftRadius: 5,
//     borderBottomRightRadius: 5,
//   },
//   dropdownItem: {
//     padding: '5px 10px',
//     fontSize: 14,
//     textOverflow: 'ellipsis',
//     overflow: 'hidden',
//     backgrounColor: '#fff',
//     cursor: "pointer",
//   }
}