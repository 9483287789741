import {DateTime} from 'luxon';
import { getUsTimestamp, getLocaleUSDate } from "../utils";

export const transformData = ( items ) => {
    if (!items || items.length == 0) {
        return {
          data: [],
        }
      }

  let transformedData = [];
  const count = items.length;
  
  for (let i = 0; i < count; i++) {
      let item = { ...items[i] };
     
      if(item.created.local){
       item.timestamp = getUsTimestamp(item.created.time)
       item.dateStr = getLocaleUSDate(item.created.time)
      }else{
        if(!item.timestamp || !item.dateStr ){

          item.timestamp = getUsTimestamp(item.created.toDate())
          item.dateStr = getLocaleUSDate(item.created.toDate())
        }
      }

      item.searchStr =  setSearchString(item) 
      transformedData.push(item);
    } 
    return {  data: transformedData };
}


let uniqueWords = []

const setSearchString = ( item ) => {
        
    let dateStr = item.dateStr && item.dateStr

        let searchStr =  (dateStr || "")
          + " " + ((item.id && item.id.toLowerCase()) || "")
          + " " + (item.address && item.address.toLowerCase() || "")
          + " " + ((item.city && item.city.toLowerCase()) || "")
          + " " + ((item.state && item.state.abbr.toLowerCase()) || "")
          + " " + ((item.state && item.state.label.toLowerCase()) || "")
          + " " + ((item.zipcode && item.zipcode.toLowerCase()) || "")
          + " " + ((item.price && item.price.toLowerCase()) || "")
          + " " + ((item.area && item.area.toLowerCase()) || "")
          + " " + ((item.remarks && item.remarks.toLowerCase()) || "")
        
        let words = searchStr.split(" ");
        for (let i = 0; i < words.length; i++) {
          if (uniqueWords.indexOf(words[i]) == -1) {
            uniqueWords.push(words[i])
          }
        }
        return searchStr;
}

export const getSearchResults = (queryString, data ) => {
    let counts = []
    let searchValue = queryString?.toLowerCase()
    let words = searchValue.split(" ");
    words.forEach(word => {
      if (word.length > 0) {
        data.forEach(item => {
          let searchIndex = item.searchStr.indexOf(word)
          while (searchIndex !== -1) {
            let existingCount = counts.find(c => c.id === item.id)
            if (!existingCount) {
              counts.push({
                id: item.id,
                count: 0,
                index: searchIndex,
              })
            } else {
              existingCount.count++;
              if (searchIndex < existingCount.index) {
                existingCount.index = searchIndex
              }
            }
            searchIndex = item.searchStr.indexOf(word, searchIndex + 1)
          }
        })
      }

    })
    counts = counts.sort((a, b) => {
      if (b.count > a.count) {
        return 1;
      }
      if (b.count < a.count) {
        return -1;
      }
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      return 0
    })
    return counts.map(c => data.find(r => r.id === c.id))

}


export const filterDateResults = (view, start, end, data ) => {
  
    let startDate = DateTime.fromJSDate(start,{zone:'America/New_York'}).set({hours:0, minute:1})
    let endDate = DateTime.fromJSDate(end,{zone:'America/New_York'}).set({hours:23, minute:59})
  
    let dateRanged; 
       dateRanged = data.filter((item) => {
        if(startDate.toMillis() && !endDate.toMillis()){
          let upper = startDate.set({hour:23, minute:59})
          return item.timestamp >= startDate &&  item.timestamp <= upper
        }
        if(startDate.toMillis() && endDate.toMillis()){
          return item.timestamp >= startDate && item.timestamp <= endDate
        }else{
          return item
        }
      });
  
      return dateRanged
  }