import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showMenu } from '../common/RootMenu';
import { BiEdit, } from 'react-icons/bi';
import { IoMdArrowDropdownCircle } from 'react-icons/io';
import { APP_COLORS } from '../../styles';
import Select from 'react-select';
import {assignPropertyToUser} from "../../store/property"
import { BsArrowsFullscreen, } from 'react-icons/bs';


export default function PropertyPanel({ index, item, activeUsers, onStatusChange, onOpenImageGallery, showFullView }) {
    const dispatch = useDispatch()
    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: '240px',
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: '10px'
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 999
        }),
    }

    let [menuOptions, setMenuOptions] = useState(null)
    let [activeUserOptions, setActiveUsers] = useState(null)
    let defaultOption = { value: item.assignedTo ? item.assignedTo.id :  'none', label: item.assignedTo ? item.assignedTo.name : 'Select to Assign', abbr: '', }
    let [activeUserSelected, setActiveUserSelected ] = useState(defaultOption)

    useEffect(() => {
        let menuOptions =
            [{
                title: null,
                items: [
                    {
                        label: 'New',
                        id: 'new',
                        disabled: item.new
                    },
                    {
                        label: 'Available',
                        id: 'available',
                        disabled: item.available
                    },
                    {
                        label: 'Pending',
                        id: 'pending',
                        disabled: item.pending,
                    },
                    {
                        label: 'Sold',
                        id: 'sold',
                        disabled: item.sold
                    },
                    {
                        label: 'Hot',
                        id: 'hot',
                        disabled: item.hot
                    },
                ]
            }]
        setMenuOptions(menuOptions)
 
        let activeUserOptions = [
            { value: 'none', label: 'Select to Assign', abbr: '', },
        ]
        activeUsers.length && activeUsers.forEach( user => {
                activeUserOptions.push({
                    value: user.id,
                    label: user.firstname + ' ' + user.lastname,
                    abbr:user.firstname
                })
        })
        setActiveUsers(activeUserOptions)
    }, [item, activeUsers])

    const assignToUser = (selectedOption) =>{
            console.log(selectedOption)
            setActiveUserSelected(selectedOption)
            if (selectedOption.value == 'none') {
                dispatch(assignPropertyToUser(selectedOption.value, item.id))
            } else {
                dispatch(assignPropertyToUser(selectedOption.value, item.id))
            }
    }

    return (
        <div style={styles.container}>
            <div style={{ alignSelf: 'center', textAlign: 'center', fontWeight: "bold" }}>
                {index + 1}
            </div>
            <div style={{ alignSelf: 'center', fontWeight: "bold" }}>
                {item.city.slice(0, 1) + '-' + item.state.abbr + '-' + (index + 1)}
            </div>
            <div 
                style={{ cursor:'pointer', textAlign:'center', alignSelf: 'center', fontWeight: "bold" }}
                onClick={()=>{showFullView({item, show:true})}}
                >
                <BsArrowsFullscreen />
            </div>
            <ImageContainer
                images={item?.images}
                onOpenImageGallery={onOpenImageGallery}
            />
            <PropertyInfo
                item={item}
                menuOptions={menuOptions}
                onStatusChange={onStatusChange}
                activeUserOptions={activeUserOptions}
                activeUserSelected={activeUserSelected}
                assignToUser = {assignToUser}
                customStyles={customStyles}
            />
            <ActionButtons
                item={item}
            />
        </div>
    )
}

const ImageContainer = ({ images, onOpenImageGallery }) => {
    return (
        <div style={styles.imageContainer}>
            {images.length > 0 && images.map(((image, idx) => (
                <div style={{ width: '50px', height: '35px' }}>
                    <img style={{ width: '50px', height: '35px', }} src={image} onClick={() => { onOpenImageGallery(idx, images) }} />
                </div>
            )))}
        </div>
    )
}
const PropertyInfo = ({ 
    item, 
    menuOptions, 
    onStatusChange, 
    activeUserOptions, 
    activeUserSelected,
    assignToUser,
    customStyles }) => {
    return (
        <>
            <div style={{
                alignSelf: 'center',
                padding: '10px 10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: item.new == false && (item.available ? '#c5e2f5' : item.pending ? '#f7ee78' : item.sold ? '#d1eeb4' : item.hot ? '#f29394' : '')
            }}>

                <div> {item.status} </div>
                <IoMdArrowDropdownCircle style={{ cursor: 'pointer', color: APP_COLORS.THEME_GRAY, fontSize: '20px' }}
                    onClick={(e) => {
                        console.log(e)
                        menuOptions && showMenu({
                            position: e.target.getBoundingClientRect(),
                            onItemClick: (optionId) => {
                                onStatusChange(item.id, optionId)
                            },
                            menuOptions,
                        })
                    }}
                />
            </div>
            <div style={{ alignSelf: 'center' }}>
            <Select
                styles={customStyles}
                name={'active_user'}
                value={activeUserSelected}
                onChange={assignToUser}
                options={activeUserOptions}
            />
            </div>
            <div style={{ alignSelf: 'center', padding: '0px 5px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {item.address}
            </div>

            <div style={{ alignSelf: 'center', padding: '0px 5px' }}>
                {item.city}
            </div>
            <div style={{ alignSelf: 'center', padding: '0px 5px' }}>
                {item.state.label}
            </div>

            <div style={{ alignSelf: 'center', padding: '0px 5px' }}>
                {item.zipcode}
            </div>
            <div style={{ alignSelf: 'center', padding: '0px 5px' }}>
                {item.area} sqft.
            </div>
            <div style={{ alignSelf: 'center', padding: '0px 5px' }}>
                {item.bed}
            </div>
            <div style={{ alignSelf: 'center', padding: '0px 5px' }}>
                {item.bath}
            </div>

            <div style={{ alignSelf: 'center', padding: '0px 5px' }}>
                ${item.price}
            </div>

            <div style={{ alignSelf: 'center', padding: '0px 5px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {item.remarks}
            </div>

            <div style={{ alignSelf: 'center', padding: '0px 5px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {item.dateStr}
            </div>

        </>
    )
}

const ActionButtons = ({ item, }) => {
    let navigate = useNavigate();
    return (
        <>
            <div style={{ alignSelf: 'center', padding: '0px 5px' }}>
                <BiEdit
                    style={{ cursor: 'pointer', }}
                    color={APP_COLORS.BUTTON_BACKGROUND}
                    size={22}
                    onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item } }) }}
                />

            </div>
        </>
    )
}
const styles = {
    container: {
        display: 'grid',
        padding: '5px 0px',
        boxSizing: "border-box",
        height: "50px",
        gridTemplateColumns: 'minmax(30px, 1fr) minmax(60px, 1fr) minmax(60px, 1fr) minmax(200px, 1.67fr) minmax(100px, 1.67fr) minmax(250px, 350px) minmax(250px, 350px) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(150px, 1.67fr) minmax(350px,2.33fr) minmax(100px, 1.67fr)  minmax(50px, 1.67fr)',
        gap: 2,
    },
    imageContainer: {
        display: 'flex',
        overflow: 'scroll',
        gap: 1,
        padding: '0px 5px'
    },
    infoContainer: {

    }
}