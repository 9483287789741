
import { BsX } from "react-icons/bs"
import { FaEdit } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { selectUserData, addZipCode, selectUpdatingUserData, deleteZipCode } from "../../store/user"
import { APP_COLORS } from "../../styles"
import Loading from "../common/Loading"
import TextField from "../common/TextField"

export default function AddZipCode({}) {
    const dispatch = useDispatch()
    let userData = useSelector(selectUserData)
    let updatingUserData = useSelector(selectUpdatingUserData)
    let zipcodes = userData.zipcodes
    
    const handleSubmit = (value) => {
        
        if(isNaN(value)){
            alert('Zip only contains numerics')
        }else{
            if(value.length !== 5){
                alert('Zip should be five digits')
            }else{
                dispatch(addZipCode(value))
            }
        }
    }

    const handleChange = (target) => {
        console.log(target.value)
    }

    const deleteZip = (zipcode) =>{
        dispatch(deleteZipCode(zipcode))
    }

    return (
    <div >
        <div style={{ color: APP_COLORS.BUTTON_BACKGROUND, fontSize:'20px', fontWeight:'bolder'}}> ZIP CODES </div>
        <div style={{ margin:'10px 0px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <div style={{width:'48%'}}>
        Add zip codes.
        </div>
        <div>
         {updatingUserData ? <Loading/> :
         <TextField
            name={'zip'}
            text={''}
            placeholder={'example. 42366'}
            onEnter={handleSubmit}
            containerStyle = {{borderRadius:3, }} 
            onTextChange={handleChange}
            autoFocus={true}
            // onCancel = {} 
            // onFocus = {}
            // onClear = {} 
        /> }   
        
        </div>
        </div>

        <div style={{display:'flex', flexWrap:'wrap', gap:5,}}>
        {zipcodes && zipcodes.length ? zipcodes.map((z,i)=>(
            <div style={{
                display:'flex', 
                justifyContent:'space-between',
                alignItems:'center',
                background:APP_COLORS.BUTTON_BACKGROUND,
                gap:5,
                borderRadius:5,
                padding:'5px 10px',
                
                }}>
                <div style={{color:APP_COLORS.SECONDARY_TEXT, }}> { z } </div>
                <div style={{cursor:'pointer', width:20, height:20}}> 
                <BsX size={20} onClick={()=>deleteZip(z)} color={APP_COLORS.SECONDARY_TEXT} /> </div>
            </div>

        )) : null}
        </div>
        
        
    </div>)
}