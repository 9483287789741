import React, { useState, useEffect, } from 'react';
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Select from 'react-select';
import { updateStatus } from '../../store/property';
import { getLocaleUSDate } from '../../utils';
import { APP_COLORS } from '../../styles';
import { filterDateResults, } from '../../store/utils';

import PropertyCard from './PropertyCard';
import PropertyPanel from './PropertyPanel';
import Loader from '../common/Loading';
import Button from '../common/Button';
import SearchContainer from '../common/SearchContainer';
import DateRangePicker from '../common/DateRangePicker';
import PropertyFullView from "../dashboard/PropertyFullView"


export default function PropertyListView({ data, user, isFetching, onSearchEnter, searchString, activeUsers }) {
    console.log(user)
    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: '240px',
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: '10px'
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 999
        }),
    }

    const dispatch = useDispatch();
    const location = useLocation();
    let view = null;
    const lightBoxOptions = {
        settings: {
            autoplaySpeed: 0,
        },
        button: {
            showAutoplayButton: false,
            showDownloadButton: false,
        }
    }
    const { openLightbox, closeLightbox } = useLightbox()

    if (location.pathname == '/dashboard/properties') {
        view = 'properties'
    }

    //   const { openLightbox, closeLightbox } = useLightbox()
    let [scrollToTopButton, setScrollToTopButton] = useState(false)

    const [dataList, setData] = useState(data.slice(0, 50))
    let [rangedData, setRangedData] = useState(null)
    let [statusFilteredData, setStatusFilteredData] = useState(null);

    const pageItems = 100;

    let [count, setCount] = useState(0)
    let [pageNumber, setPageNumber] = useState(1);
    let [dateRange, setDateRange] = useState(null)

    let [statusOptionSelected, setstatusOptionSelected] = useState({ value: 'all', label: 'All Properties (Default)', abbr: '' })
    let [statusOptions, setStatusOptions] = useState()
    const [images, setImages] = useState([])
    const [imageIndex, setImageIndex ] = useState(0)

    let [showFullView, setShowFullView] = useState({item:null, show:false})

    useEffect(() => {
        document.addEventListener('scroll', showScrollButton)
        let statusOptions = [
            { value: 'all', label: 'All Properties (Default)', abbr: '', },
            { value: 'new', label: 'New Properties', abbr: '', },
            { value: 'available', label: 'Available Properties', abbr: '', },
            { value: 'pending', label: 'Pending Properties', abbr: '', },
            { value: 'sold', label: 'Sold Properties', abbr: '', },
            { value: 'hot', label: 'Hot Properties', abbr: '', },
        ]
        setStatusOptions(statusOptions);
        return () => document.removeEventListener('scroll', showScrollButton)
    }, [])


    useEffect(() => {
        let paginatedData;
        let dataToUse = statusFilteredData || rangedData || data
        setCount(Math.ceil(dataToUse.length / pageItems));
        paginatedData = dataToUse.slice((pageNumber - 1) * pageItems, (pageNumber * pageItems))
        setData(paginatedData)
        window.scroll({
            top: 0,
            behavior: 'instant'
        });
    }, [pageNumber, data, rangedData, statusFilteredData])


    const handleStatusChange = (selectedOption) => {
        console.log(selectedOption)
        setstatusOptionSelected(selectedOption)
        if (selectedOption.value == 'all') {
            setStatusFilteredData(null)
        } else {
            let filteredData = data.filter(d => d.status == selectedOption.value)
            setStatusFilteredData(filteredData)
        }
    }


    const showScrollButton = () => {
        const scrollTop = window.scrollY;
        if (scrollTop >= 400) {
            setScrollToTopButton(true);
        } else {
            setScrollToTopButton(false);
        }
    }

    let [selectedItem, setIsSelected] = useState(null);
    let [showDialogue, setShowDialogue] = useState(null);


    useEffect(() => {
        if (images.length) {
            openLightbox(imageIndex,images);
        }
    }, [images])

    const showLightBox = (idx, images) => {
        let imageSources = [];
        images?.forEach(image => {
            imageSources.push({ src: image })
        })
        setImages(imageSources)
        setImageIndex(idx)
    }

    const onDateRangeSelected = (start, end) => {
        if (start) {
            setDateRange({ start: start, end: end })
            let dateRanged = filterDateResults(view, start, end, data)
            setRangedData(dateRanged)
        } else {
            setDateRange(null)
            setRangedData(null)
        }
    }

    const searchText = (queryString) => {
        setRangedData(null)
        onSearchEnter(queryString)
    }

    const onStatusChange = (id, menuOption) => {
        dispatch(updateStatus(id, menuOption))
    }

    return (
        <div
            style={{ minHeight: '90vh', }}
            onClick={(e) => {
                setIsSelected(null)
            }}>
            {!isFetching && dataList ?
                <table style={{ minWidth: '99%' }}>
                    <div style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 10,
                        background: APP_COLORS.PAGE_BACKGROUND,
                        boxShadow: 'rgba(100, 100, 112, 0.2) 0px 4px 6px 0px',
                    }}>
                        <div style={{
                            padding: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 10,
                        }}>
                            <SearchContainer
                                onEnter={searchText}
                                searchString={searchString}
                            />
                            <div style={{ width: '25%' }}>
                                <DateRangePicker
                                    onSelected={onDateRangeSelected}
                                />
                            </div>
                            {view == 'properties' &&
                                <div>
                                    <Select
                                        styles={customStyles}
                                        name={'status'}
                                        value={statusOptionSelected}
                                        onChange={handleStatusChange}
                                        options={statusOptions}
                                    />
                                </div>
                            }
                        </div>
                        <div style={{ fontSize: '12px', padding: 10, justifyContent: 'space-between', alignItems: 'center' }}>
                            Found {dataList.length} items.
                            {(searchString || rangedData || statusFilteredData) &&
                                <div>
                                    <span>Filters</span> <br />
                                    {searchString && (<> <b>{`${`Search '` + searchString + `'`}`} </b> <br /> </>)}
                                    {rangedData && (<><b>{`${`From '` + getLocaleUSDate(dateRange.start) + `' to  '` + getLocaleUSDate(dateRange?.end) + `'`}`}</b> <br /> </>)}
                                    {statusFilteredData && (<> <b>{`${`Status '` + statusOptionSelected.value + `'`}`} </b>  </>)}
                                    {/* Showing page {pageNumber} of {count} . (Total Items : {data.length}) */}
                                </div>
                            }
                            {/* <div>
                            <Pagination
                                count={count}
                                variant='outlined'
                                shape='rounded'
                                onChange={(e, page) => {
                                console.log(page)
                                setPageNumber(page)
                                }}
                            />
                            </div> */}
                        </div>
                        {user?.admin &&
                            <div style={styles.panelHeader}>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Sr
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Code
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Open 
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Images
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Status
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Assigned
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Address
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    City
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    State
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Zipcode
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Area
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Bed
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Bath
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Price
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Description
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Listed On
                                </div>
                                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                                    Edit
                                </div>

                            </div>}
                    </div>

                    {user?.admin && dataList.length ? dataList.map((item, i) => (
                        <tr style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'flex-start',
                            fontSize: '14px',
                        }}>
                            <PropertyPanel
                                key={i}
                                index={i}
                                item={item}
                                activeUsers={activeUsers}
                                onStatusChange={onStatusChange}
                                onOpenImageGallery={(idx, images) => { showLightBox(idx, images) }}
                                showFullView={({item, show})=>{ setShowFullView({item:item, show:show})}}
                            />
                        </tr>
                    )) : null
                    }

                    {user?.realtor && dataList.length ?
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr)',
                            padding: 20,
                            gap: 10,
                            width:'82vw'
                        }}>
                            {dataList.map((item, i) => (
                                <PropertyCard
                                    key={i}
                                    index={i}
                                    item={item}
                                    showFullView={({item, show})=>{ setShowFullView({item:item, show:show})}}
                                    onOpenImageGallery={(idx, images) => { showLightBox(idx, images) }}
                                    onItemClick={((itemId) => {
                                        setIsSelected({ itemId: itemId })
                                    })}
                                    isSelected={item.id == selectedItem?.itemId}
                                />

                            ))}
                        </div> : null
                    }
                    {!dataList.length && <div style={{ padding: 30, fontWeight: 'bold' }}> No results </div>}
                </table> :
                isFetching && <div style={{ display: 'flex', flexDirection: 'row', }}> <Loader />  </div>
            }

            {showFullView.show && 
                <PropertyFullView 
                    item={showFullView.item} 
                    onClose={()=>{setShowFullView({item:null, show:false})}}
                    onOpenImageGallery={(idx, images) => { showLightBox(idx, images) }}
                    />
            }

            {images?.length ? <SRLWrapper elements={images} options={lightBoxOptions} /> : null}
            
            {scrollToTopButton &&
                <div style={{ position: 'fixed', zIndex: 2, bottom: 10, right: 10 }}>
                    <Button
                        style={{ padding: 10 }}
                        icon={{ name: 'arrow-up-circle-fill', family: "BootstrapIcons" }}
                        onClick={() => {
                            window.scroll({
                                top: 0,
                                behavior: 'smooth'
                            })
                        }}
                    />
                </div>}

            {/* {showDialogue && showDialogue.type == 'remarks_dialog' &&
                <Dialogue
                    headerText={'Add Remarks'}
                    content={<AddRemarks id={showDialogue.itemId} view={showDialogue.view} onClose={() => setShowDialogue(null)} />}
                    show={showDialogue.show}
                    onClose={() => setShowDialogue(null)}
                />
            } */}


        </div>
    )
}


const styles = {
    panelHeader: {
        display: 'grid',
        background: APP_COLORS.BUTTON_BACKGROUND,
        color: "white",
        fontWeight: 'bold',
        gridTemplateColumns: ' minmax(30px, 1fr) minmax(60px, 1fr) minmax(60px, 1fr) minmax(200px, 1.67fr) minmax(100px, 1.67fr)  minmax(250px, 350px)  minmax(250px, 350px) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(100px, 1.67fr) minmax(150px, 1.67fr) minmax(350px,2.33fr) minmax(100px, 1.67fr)  minmax(50px, 1.67fr)',
        gap: 1,
        alignItems: 'end',
        margin: '0px 5px',
    }
}