import { createSlice } from '@reduxjs/toolkit'
import * as api from '../services/api';
import { db, functions, auth } from '../services/config';
import { httpsCallable } from "firebase/functions";
import { arrayRemove, arrayUnion, doc, setDoc } from 'firebase/firestore';
import { sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';

const initialState = {
  user: null,
  isFetchingUserData: false,
  isLoggedIn: false,
  loggingIn: false,
  loginError: null,
  signUpStatus: null,
  updatingUserData: false,
  allUsers: []
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loggingIn(state, action) {
      state.loggingIn = action.payload
    },
    userAuthenticated(state, action) {
      state.loggingIn = false
      state.isLoggedIn = true
      state.loginError = null
    },
    setUserData(state, action) {
      state.user = action.payload
    },
    setLoginError(state, action) {
      state.loggingIn = false;
      state.loginError = action.payload
    },
    userLoggedOut(state, action) {
      state.user = null;
      state.isLoggedIn = false;
      state.loginError = false;
    },
    signUpStatus(state, action) {
      state.signUpStatus = action.payload
    },
    updatingUserData(state, action) {
      state.updatingUserData = action.payload
    },
    updateZipCodes(state, action) {
      let { value, remove } = action.payload
      let user = state.user
      if (remove) {
        let index = user.zipcodes.findIndex(z => z == value)
        user.zipcodes.splice(index, 1)
      } else {
        user.zipcodes = [...user.zipcodes || [], value]
      }
    },
    sendingVerificationEmail(state, action) {
      state.emailVerificationStatus = action.payload
    },
    setResetPasswordStatus(state, action) {
      state.resetPasswordStatus = action.payload
    },
    setAllUsers(state, action) {
      let users = action.payload
      state.allUsers = users.filter(u => (u.role != 'admin' || !u.role))
    },
    onUpdateUserPlan(state, action) {
      let data = action.payload
      let users = state.allUsers || []
      users.forEach(u => {
        if (u.id == data.id) {
          u.isActivated = data.isActivated
        }
      })
    }

  }
})
export default userSlice.reducer

export const {
  loggingIn,
  userAuthenticated,
  setUserData,
  setLoginError,
  userLoggedOut,
  signUpStatus,
  setAddingZipCode,
  updatingUserData,
  updateZipCodes,
  sendingVerificationEmail,
  setResetPasswordStatus,
  setAllUsers,
  onUpdateUserPlan
} = userSlice.actions


// export const addUserToFirebase = (userCredentials) => async (dispatch, getState) => {
//   dispatch(setAddingUser())

//   api.registerWithEmailAndPassword(userCredentials).then(
//     (response) => {
//       console.log('response', response)
//       dispatch(setUserAdded({status:true, newUser: response }))
//     }
//   ).catch(
//     err => console.log(err)
//   )
// }

export const logoutUser = () => async (dispatch, getState) => {
  dispatch(userLoggedOut());
  sessionStorage.removeItem('userId');
  api.logout();
}

export const login = (userCredentials) => async (dispatch, getState) => {
  dispatch(loggingIn(true))
  api.authenticateUser(userCredentials).then(
    (response) => {
      if (response.user) {
        console.log(response.user)

        let uid = response.user.uid
        sessionStorage.setItem('userId', uid);
        dispatch(userAuthenticated())
        // dispatch(setLoggedInUserData(uid))
      }
      if (response.error) {
        console.log(response.error)
        dispatch(setLoginError(response.error))
      }
    }
  ).catch(err => console.log(err)
  )
}

export const setLoggedInUserData = ({ user, idTokenResult }) => async (dispatch, getState) => {
  console.log(user, idTokenResult)
  let uid = user.uid
  api.getDocById({ table: "users", id: uid }).then((userData) => {
    userData = {
      ...userData,
      admin: idTokenResult.claims.admin ? true : false,
      realtor: idTokenResult.claims.realtor ? true : false,
      isEmailVerified: user.isEmailVerified,
    }
    dispatch(setUserData(userData))
  })
}

export const signUp = (userCredentials) => async (dispatch, getState) => {
  dispatch(loggingIn(true))
  api.registerWithEmailAndPassword(userCredentials).then(
    (response) => {
      console.log('response', response)
      dispatch(loggingIn(false))
      dispatch(signUpStatus('SUCCESS'))
      // dispatch(setUserAdded({status:true, newUser: response }))
    }
  ).catch(
    err => {
      console.log(err)
      dispatch(loggingIn(false))
      dispatch(signUpStatus('ERROR', err))
    }
  )
}

export const setCustomClaimForAdmin = (id) => async (dispatch, getState) => {
  const setAdminToken = httpsCallable(functions, 'setAdminToken');
  setAdminToken(id).then((result) => {
    console.log(result)
  }).catch(err => console.log(err))
}

export const updateUser = (data) => async (dispatch, getState) => {
  dispatch(updatingUserData(true))
  let user = getState().users.user
  let userObject = {
    id: user.id,
    firstname: data.firstname,
    lastname: data.lastname,
    cell: data.cell,
    landline: data.landline,
    bio: data.bio,
    company: data.company,
    address: {
      street: data.street,
      city: data.city,
      state: data.state,
      zip: data.zip
    }
  }
  await api.setDocById({ collection: 'users', data: userObject })
  user = {
    ...user,
    ...userObject
  }
  dispatch(updatingUserData(false))
  dispatch(setUserData(user))
}

export const addZipCode = (value) => async (dispatch, getState) => {
  console.log(value)
  let user = getState().users.user
  dispatch(updatingUserData(true))

  const userRef = doc(db, "users", user.id);
  await setDoc(userRef, {
    zipcodes: arrayUnion(value)
  }, { merge: true });
  dispatch(updateZipCodes({ value }))
  dispatch(updatingUserData(false))
}

export const deleteZipCode = (value) => async (dispatch, getState) => {
  console.log(value)
  let user = getState().users.user
  dispatch(updatingUserData(true))

  const userRef = doc(db, "users", user.id);
  await setDoc(userRef, {
    zipcodes: arrayRemove(value)
  }, { merge: true });

  dispatch(updateZipCodes({ value, remove: true }))
  dispatch(updatingUserData(false))
}

export const verifyEmail = () => async (dispatch, getState) => {
  dispatch(sendingVerificationEmail({ sending: true }))
  sendEmailVerification(auth.currentUser)
    .then(() => {
      // Email verification sent!
      // ...
      dispatch(sendingVerificationEmail({ sent: true }))
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.err(error)
      dispatch(sendingVerificationEmail({ failed: true }))
      // ..
    });;
}

export const resetPassword = () => async (dispatch, getState) => {
  let user = getState().users.user
  dispatch(setResetPasswordStatus({ sending: true }))
  sendPasswordResetEmail(auth, user.email)
    .then(() => {
      // Password reset email sent!
      // ..
      dispatch(setResetPasswordStatus({ sent: true }))
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.err(error)
      dispatch(setResetPasswordStatus({ failed: true }))
      // ..
    });
}

export const getAllUsers = () => async (dispatch, getState) => {
  let user = getState().users.user
  if (user.admin) {
    // dispatch(gettingAllUsers)
    api.getAllDocs({ collectionName: 'users', order: 'email', }).then(res => {
      console.log(res)
      dispatch(setAllUsers(res))
    })
  }
}

export const updateUserPlan = (user, optionId) => async (dispatch, getState) => {
  let isActivated = user.isActivated
  if (optionId == 'deactivate_plan') {
    isActivated = false
  } else if (optionId == 'activate_plan') {
    isActivated = true
  }
  let data = {
    id: user.id,
    isActivated: isActivated
  }
  await api.setDocById({ collection: 'users', data: data })
  dispatch(onUpdateUserPlan(data))

}


export const selectIsLoggedIn = (state) => state.users.isLoggedIn
export const selectIsLoggedOut = (state) => !state.users.isLoggedIn
export const selectIsLogginIn = (state) => state.users.loggingIn
export const selectUserData = (state) => state.users?.user
export const selectLoginError = (state) => state.users.loginError

export const selectsignUpStatus = (state) => state.users.signUpStatus

export const selectUpdatingUserData = (state) => state.users.updatingUserData
export const selectEmailVerificationStatus = (state) => state.users.emailVerificationStatus
export const selectResetPasswordStatus = (state) => state.users.resetPasswordStatus


export const selectAllUsers = (state) => state.users.allUsers
export const selectAllActiveUsers = (state) => state.users.allUsers.filter(u => u.isActivated) 