import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector , useDispatch} from 'react-redux';
import {  BsInfoCircle, BsPersonCircle } from 'react-icons/bs';
import {showMenu} from '../common/RootMenu'
import { APP_COLORS } from '../../styles';
import logo from '../../assets/logo.png';

import  {selectUserData,  selectIsLoggedIn, logoutUser } from '../../store/user';
import { ADD_PROPERTY_ROUTE, DASHBOARD_PAGE_ROUTE, EDIT_PROPERTY_ROUTE, HOME_PAGE_ROUTE, LISTED_PROPERTY_ROUTE, SOLD_PROPERTY_ROUTE } from '../../routes';

export default function DashHeader() {
    let navigate = useNavigate()
    let isLoggedIn = useSelector(selectIsLoggedIn)
    const dispatch = useDispatch()
    let user = useSelector(selectUserData)    

    const menuOptions =  [{
        title: `Welcome`,
        items: [
          {
            label: 'Profile',
            id: 'profile',
          },
          {
            label: 'Log Out',
            id: 'logout',
          }]
      }]    

    const onMenuOptionClicked = (id) =>{
        if(id == 'profile'){

        }else if(id == 'logout'){
            console.log('logout')
            dispatch(logoutUser())
        }
    }   

    return (
        <div style={styles.header} >
                
                <div onClick={()=>{navigate(HOME_PAGE_ROUTE)}} style={styles.sm_logo} />
                
                <div style={{display:'flex', alignItems:'center', marginRight:10,}}>
                <div 
                style={{display:'flex', alignItems:'center', fontSize:'16px'}}
                onClick={(e)=>{
                    menuOptions && showMenu({
                        position: e.target.getBoundingClientRect(),
                        onItemClick: (id) => {
                        onMenuOptionClicked(id)
                    },
                    menuOptions,
                    })
                }}>
                    <BsPersonCircle size={22} style={{
                        cursor: 'pointer',
                        borderRadius: '50%',
                        color: APP_COLORS.BUTTON_BACKGROUND,
                        margin: '0px 2px',
                    }} /> 
                </div>
                
                </div>   
        </div>
    )
}

const styles = {
    header: {
        background: APP_COLORS.META_BACKGROUND,
        boxShadow: 'rgba(100, 100, 111, 0.2) 12px 4px 11px 0px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        zIndex:9,
    },
    sm_logo: {
        backgroundImage: `url(${logo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '50px',
        width: '200px',
        top: 0,
        left: 5,
        zIndex: 9,
        cursor:'pointer',
    },

   
    listStyle: {
        listStyle: 'none',
        display: 'flex',
        gap: 20,
        justifyContent: "center",
        margin:0
    },

}