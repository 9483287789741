import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "../components/common/Button"
import Loading from "../components/common/Loading"
import TextField from "../components/common/TextField"
import { 
    selectUpdatingUserData, 
    selectUserData, 
    updateUser, 
    verifyEmail, 
    selectEmailVerificationStatus, 
    resetPassword, 
    selectResetPasswordStatus} from "../store/user"
import { APP_COLORS } from "../styles"

export default function Profile ( ) {
    let dispatch = useDispatch()

    let updatingUserData = useSelector(selectUpdatingUserData)
    let user = useSelector(selectUserData)

    let emailVerificationStatus = useSelector(selectEmailVerificationStatus)
    let resetPasswordStatus = useSelector(selectResetPasswordStatus)

    let [ formData, setFormData ] = useState({
        firstname:user.firstname || '',
        lastname:user.lastname || '',
        cell:user.cell || '',
        landline:user.landline || '',
        bio:user.bio || '',
        company:user.company || '',
        street: user.address?.street || '',
        city: user.address?.city || '',
        state: user.address?.state || '',
        zip: user.address?.zip || ''
    })

    console.log(formData)

    const handleChange = (target) => {
        console.log(target.name, target.value)
        setFormData( data => ({
            ...data,
            [target.name] : target.value
        }))
    }

    const updateProfile = () => {
        console.log(formData)
        dispatch(updateUser(formData))
    }
    
    return (<div style={{padding:20, width:'80vw' }} >
         {!user.isEmailVerified && 
        <div style={{
            padding:10,
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            background:APP_COLORS.META_BACKGROUND,
            width:'100%',
            height:40,
            borderRadius:10,
            }}>
            <div> <span style={{fontWeight:'bold'}}>{user.email}</span> is not verified</div>
            <div 
                style={{
                    cursor:'pointer',  
                    color: !emailVerificationStatus?.sent && APP_COLORS.BUTTON_BACKGROUND
                }}
                onClick={()=> !emailVerificationStatus?.sent && dispatch(verifyEmail())}    
                >{emailVerificationStatus?.sent ? 'Verification email sent, please check your inbox.' : 'verify now'}
                </div>
            </div>}
            <div style={{background:APP_COLORS.META_BACKGROUND,
            width:'100%',
            height:40,
            borderRadius:10, 
            display:'flex', 
            margin:'10px 0px',
            padding:10,
            alignItems:'center', 
            gap:10}}>
            {!resetPasswordStatus?.sent && <div> Receive a password reset link on your email and follow the instructions. </div>}
            <div>{
                resetPasswordStatus?.sending ? <Loading /> : resetPasswordStatus?.sent ? 'Password reset email sent.' : 
                <Button 
                    style={{fontSize:'16px', background:'none', color:APP_COLORS.BUTTON_BACKGROUND }}
                    label="Reset Password"
                    onClick={()=>{ dispatch(resetPassword())}}
                    />
                }
                
            </div>
        </div>

        <div style={{
            color:APP_COLORS.PRIMARY_TEXT,
            fontSize:'20px', 
            fontWeight:'bolder', 
            margin:'10px 0px'
            }}>Profile Information</div>

        <div style={{display:'flex', flexDirection:'column', width:'100%', gap:20, padding:10 }}>
            <div style={{display:'flex', gap:20, alignItems:'center'  }}>
                <div style={{width:100}}>Name</div>
                <div style={{flex:1}}>
                <TextField  
                    name='firstname'
                    text= {formData.firstname}
                    onTextChange={handleChange}
                    placeholder={'firstname'} />
                </div>
                <div style={{flex:1}}>
                <TextField 
                    name='lastname'
                    text= {formData.lastname}
                    onTextChange={handleChange}
                    placeholder={'lastname'} />
                </div>
            
            </div>
            <div style={{display:'flex', gap:20, alignItems:'center'  }}>
            <div style={{width:100}}>Phone</div>
            <div style={{flex:1}}>
                <TextField 
                    name='cell'
                    text= {formData.cell}
                    onTextChange={handleChange}
                    placeholder={'cell'} />
                </div>
                <div style={{flex:1}}>
                <TextField 
                    name='landline'
                    text= {formData.landline}
                    onTextChange={handleChange}
                    placeholder={'landline'} />
                </div>
            </div>
            <div style={{display:'flex', gap:20, alignItems:'center'  }}>
            <div style={{width:100}}>Bio</div>
            
                <div style={{flex:1}}>
                <TextField 
                    name='bio'
                    text= {formData.bio}
                    onTextChange={handleChange}
                    multiLine={true} placeholder={'add a bio'} />
                </div>
            </div>
            <div style={{display:'flex', gap:20, alignItems:'center'  }}>
            <div style={{width:100}}>Company</div>
                <div style={{flex:1}}>
                <TextField 
                 name='company'
                 text= {formData.company}
                 onTextChange={handleChange}
                 placeholder={'company'} />
                </div>
            </div>
            <div style={{display:'flex', gap:20, alignItems:'center'  }}>
            <div style={{width:100}}>Address</div>
                <div style={{flex:1}}>
                <TextField  
                    name='street'
                    text= {formData.street}
                    onTextChange={handleChange}
                    placeholder={'street'} />
                </div>
                <div style={{flex:1}}>
                <TextField  
                    name='city'
                    text= {formData.city}
                    onTextChange={handleChange}
                    placeholder={'city'} />
                </div>
                <div style={{flex:1}}>
                <TextField  
                    name='state'
                    text= {formData.state}
                    onTextChange={handleChange}
                    placeholder={'state'} />
                </div>
                <div style={{flex:1}}>
                <TextField  
                    name='zip'
                    text= {formData.zip}
                    onTextChange={handleChange}
                    placeholder={'zip'} />
                </div>
                
            </div>
            <div style={{display:'flex', justifyContent:'center', margin:'20px 0px', }}>
                {updatingUserData ? 
                <Loading/> : 
                <Button 
                    onClick={updateProfile}
                    style={{fontSize:'18px'}} 
                    label={'Update Profile'} />
                }
                
            </div>
        </div>

    </div>)
}