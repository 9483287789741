import React from "react";

import {APP_COLORS} from '../../styles';

function OptionsMenu({ menuOptions, positionStyles, onItemClick }) {
  return (
    <div style={{...styles.container, ...positionStyles}}>
      {menuOptions.map(group => (<>
        {!!group.title && <div style={styles.menuTitle}>
          {group.title}
        </div>}
        {!!group.startLine && <div style={styles.line} />}
        {group.items.map(item => (
        <div 
          onClick={(e) => {
            e.stopPropagation()
            !item.disabled && onItemClick && onItemClick(item.id, item.s_id)
          }}
          className="menu-option" 
          style={styles.option(item.disabled)} >
          <div> {item.label}</div> 
        </div>))}
        {!!group.endLine && <div style={styles.line} />}
      </>))}
    </div>
  );
}

export default OptionsMenu;

const styles = {
  container: {
    position: 'absolute',
    right:0,
    width: 160,
    zIndex: 99,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 2px 4px 0px',
    background: APP_COLORS.SECONDARY_TEXT,
    borderRadius: 5
  },
  line: {
    borderBottom: `1px solid ${APP_COLORS.THEME_GRAY}`
  },
  menuTitle: {
    
    fontSize: 10,
    padding: 10,
    paddingTop: 15,
  },
  option: (disabled)=>({
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    color: disabled ? '#bbb' : '#212121',
    fontSize: 14,
    padding: 12,
    paddingLeft: 22
  }),
 
}
