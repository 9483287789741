
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectUserData, } from '../store/user';
import { ROLES } from '../constants';
import AdminDash from '../components/dashboard/AdminDash';
import RealtorDash from '../components/dashboard/RealtorDash';

export default function DashboardIndex() {

  let user = useSelector(selectUserData)
  
  return (
    <>
      <div style={{
         position:'relative',
         height:'100%',
         overflowY:'scroll',
         padding:'0px 0px',
         paddingRight:1,
         boxSizing:'border-box',
         width:'85vw',
      }} >
        {user.admin ? <AdminDash/> : <RealtorDash/>}
      </div>
      <Outlet />
    </>)
}

