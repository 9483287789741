
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserData, } from '../store/user';
import { ROLES } from '../constants';

import { APP_COLORS } from "../styles";
import DashHeader from '../components/dashboard/DashHeader';
import SideBar from '../components/dashboard/Sidebar';

export default function Dashboardpage() {

  return (
    <div >
      <DashHeader />
      <div style={{ display:'flex', }}>
          <SideBar/>
        <div style={styles.pageStyles}>
        <Outlet />
        </div>
      </div>
    </div>)
}

const styles = {
  pageStyles: {
    height: 'calc(100vh - 50px)',
  },
}
