import { APP_COLORS } from '../../styles';


function Button ({label, tooltip, onClick , style, icon}){

  return( 
    <div
    onClick={onClick}
    style={{...styles.button, ...style}}
    >
    {label}
    {/* {!!icon && <Icon name={icon.name} family={icon.family} styles={styles.icon} />} */}
  </div>)
}
export default Button;

const styles={
  button:{
    display:'flex',
    flexDirection:'row',
    justifyContent:"center", 
    alignItems:"center",
    gap:5,
    background:APP_COLORS.BUTTON_BACKGROUND,
    color:APP_COLORS.SECONDARY_TEXT,
    fontSize:'24px',
    padding:'10px 20px',
    borderRadius:'10px',
    outline:'none',
    cursor:'pointer',
    textAlign:'center'
  },
  icon:{
    fontSize:'14px'
  }
}