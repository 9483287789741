
import { FaCog, FaReceipt, FaUser} from "react-icons/fa";
import {  useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserData } from "../../store/user";
import { APP_COLORS } from "../../styles";
import AddZipCode from "./AddZipCode";
import ProfileCard from "./ProfileCard";

export default function RealtorDash ( ){ 
    let user = useSelector(selectUserData)
    let navigate = useNavigate()
    

    return(
        <div style={styles.container}>
        
        <div style={{
             display: 'grid',
             gridTemplateColumns: 'repeat(auto-fit, minmax(265px, 1fr))', /* Where the magic happens */
             gridAutoRows: '94px',
             gridGap: '20px',
             margin: '20px',
            }}>
            <div style={styles.overViewCard}>
                <div> <FaUser size={20} color={APP_COLORS.SECONDARY_BACKGROUND} /> </div>
                <div>  {user.firstname || user.email}  </div>
            </div>
            
            <div style={styles.overViewCard} onClick={()=>navigate('/dashboard/profile')}>
                <div> <FaCog size={20} color={APP_COLORS.SECONDARY_BACKGROUND} /> </div>
                <div> Profile Settings </div>
            </div>
            
            
            <div style={styles.overViewCard}>
                <div > <FaReceipt size={20} color={APP_COLORS.SECONDARY_BACKGROUND} /> </div>
                <div > Terms And Conditions </div>
            </div>
        </div>
        <div style={{
            columnCount: '2',
            columnGap: '20px',
            margin: '20px' }}>
        <div style={{...styles.card, height:485}}>
            <ProfileCard />
        </div>
        <div style={{...styles.card, height:200}}>
            <AddZipCode />
        </div>
        <div style={{...styles.card, height:265}}>  </div>
        </div>
        </div>
    )
}

const styles = {
    container:{
       padding:20,
    },
    overViewCard:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
        backgroundColor: APP_COLORS.META_BACKGROUND,
        cursor:'pointer'
    },
    card:{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: APP_COLORS.META_BACKGROUND,
        marginBottom: '20px',
        padding: '24px',
        boxSizing: 'border-box',
    }
}