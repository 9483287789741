import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import { BsX } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import FileUploader from '../common/FileUploader';
import TextField from '../common/TextField';
import Loader from '../common/Loading';
import Button from '../common/Button';
import Select from 'react-select';
// import Select1 from '../common/Select';
import { APP_COLORS } from '../../styles';
import { validate, checkForErrors, US_STATES } from '../../utils';
import { 
    addProperty,
    selectIsAddingProperty, 
    selectIsPropertyAdded, 
    selectIsUploadingImages,
    setPropertyAdded,
    editProperty
} from '../../store/property';

export default function AddorEditProperty() {
  

let params = useParams();
let {state} = useLocation();

// console.log(params.id, state);

let data = state?.item;
//   const { openLightbox, closeLightbox } = useLightbox()
//   const options = {
//     settings: {
//       autoplaySpeed: 0,
//     },
//     button: {
//       showAutoplayButton: false
//     }
//   }

const isEdit = !!data;

const customStyles = {
    container: (provided, state) => ({
      ...provided,
      marginTop: 5
    }),
    control: (provided, state) => ({
      ...provided,
      padding: 5,
      background: APP_COLORS.PAGE_BACKGROUND,
      borderRadius: '10px'
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999
    }),
  }
  const navigate = useNavigate()

  const dispatch = useDispatch();
  let isAddingProperty = useSelector(selectIsAddingProperty)
  let isUploadingImages = useSelector(selectIsUploadingImages)
  let isPropertyAdded = useSelector(selectIsPropertyAdded)

  let [propertyData, setPropertyData] = useState({
    address: data?.address || "",
    city: data?.city || "",
    state: data?.state || "",
    zipcode : data?.zipcode || "",
    price: data?.price || '',
    area: data?.area || '',
    bath: data?.bath || '',
    bed: data?.bed || '',
    remarks: data?.remarks || "",
  });

  
  let [prevImages, setPrevImages ] = useState(data?.images)
  let [imageFiles, setImageFiles] = useState( [])
  let [previewImages, setPreviewImages] = useState([])

//   let [lightBoxImage, setLightBoxImage]  = useState(null)
  
  let [errors, setErrors] = useState({})
  let errorRef = useRef(errors);


  useEffect(() => {
    window.scrollTo({
      top: 0
    })
    return () => {
      dispatch(setPropertyAdded(false))
    }
  }, [])

  useEffect(() => {
    if (isPropertyAdded)
    { 
      navigate('/dashboard/properties')
      window.location.reload();
    }
  }, [isPropertyAdded])


  const handleChange = (target) => {
    const { name, value } = target;
    let returnedErrors = validate(name, value)
    // console.log(returnedErrors[name])
    setErrors(errors => ({
      ...errors,
      [name]: returnedErrors[name]
    }));
    setPropertyData(prev => ({
      ...prev,
      [name]: value
    })
    )
  }

  const handleStateChange = (selectedOption) => {
    console.log(selectedOption)
    setPropertyData(prev => ({
      ...prev,
      state: selectedOption
    }))
  }

  const handleSubmit = () => {
    for (const key in propertyData) {
      let returnedErrors = validate(key, propertyData[key])
      errorRef.current[key] = returnedErrors[key]
      setErrors(errors => ({
        ...errors,
        [key]: returnedErrors[key]
      }));
    }
    let hasErrors = checkForErrors(errorRef.current)
    if (!hasErrors) {
        let formData = {...propertyData, previewImages:previewImages, imageFiles:imageFiles }
            if(isEdit){
              formData.images = prevImages
              formData.id = data.id
              console.log(formData)
              dispatch(editProperty(formData))
            }else{
              console.log(formData)
              if(imageFiles.length >0 ){
                dispatch(addProperty(formData))
              }else{
                  toast.error('Fill Required Fields!')      
              }
              
            }  
    } else {
      toast.error('Fill Required Fields!')
    }
  }


  const onFilesDropped = (acceptedFiles) => {
    setImageFiles(prev => ([...prev, ...acceptedFiles]))
    let previewUrls = [];
    acceptedFiles?.forEach((file, index) => {
      let url = URL.createObjectURL(file);
      previewUrls.push({ src: url })
    })
    setPreviewImages(prev => ([
      ...prev,
      ...previewUrls
    ]))
  }
  const onImageCancel = (index) => {
    let images = [...imageFiles];
    let pImages = [...previewImages];
    images.splice(index, 1)
    pImages.splice(index, 1)
    setImageFiles(images)
    setPreviewImages(pImages)
  }

//   useEffect(()=>{
//     if(!!lightBoxImage){
//       openLightbox(lightBoxImage.index); 
//     }
//   },[lightBoxImage])

//   const showLightBoxImages = ( previewImages, imgIdx ) =>{
//     setLightBoxImage({images:previewImages, index:imgIdx})
//   }
  

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>

        <div style={{ margin: 10,  }}>
          <div style={{display:'flex', flexDirection:'column', }}>
            
            {prevImages && 
            <div> <b>Previous Images  </b>
              <div style={{display:'flex', height:'110px', gap:10, overflowY: 'scroll', padding:10,}}>
              {prevImages.length ? prevImages.map((image, idx) => (
                <div key={idx} style={{ height: "100px", width: '100px', position: 'relative' }} >  
                {/* <BsX 
                onClick={(e) => { onImageCancel(idx)}} size={18}
                style={{
                    background: 'white',
                    left: '90%', top: 2,
                    borderRadius: '50%',
                    position: 'absolute',
                    filter: 'drop-shadow(1px 1px 1px rgb(0 0 0 / 0.6))',
                    zIndex: 99,
                    cursor:'pointer'
                  }}
                /> */}
                <img src={image} 
                  //   onClick={e => { onImagePreview(previewImages,idx) }} 
                  style={{ width: '100%', height: '100%' }} 
                />

                </div>
              )
              ) : null}
              </div>
              </div>}
            
            <div style={{display:'flex', flexDirection:'row', margin:10, }}>
            <FileUploader
              onFilesDropped={onFilesDropped}
              text={'Click or Drag n Drop Images Here!'}
            />
            <div style={styles.imageContainer}>
              {previewImages.length ? previewImages.map((image, idx) => (
                <div key={idx} style={{ height: "200px", width: '200px', position: 'relative' }} >  
                <BsX 
                onClick={(e) => { onImageCancel(idx)}} size={18}
                style={{
                    background: 'white',
                    left: '90%', top: 2,
                    borderRadius: '50%',
                    position: 'absolute',
                    filter: 'drop-shadow(1px 1px 1px rgb(0 0 0 / 0.6))',
                    zIndex: 99,
                    cursor:'pointer'
                  }}
                />
                <img src={image.src} 
                  //   onClick={e => { onImagePreview(previewImages,idx) }} 
                  style={{ width: '100%', height: '100%' }} 
                />

                </div>
              )
              ) : null}
            </div>
            </div>
            
          </div>

          <div style={styles.fieldPanel}>
          <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Address  <sup style={{ color: APP_COLORS.LINK_ORANGE }}>*</sup> </div>
              <TextField
                name={'address'}
                text={propertyData.address}
                type={'text'}
                placeholder={'Enter Complete Address'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                inputStyle={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.address}</span>
            </div>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> City </div>
              <TextField
                name={'city'}
                text={propertyData.city}
                type={'text'}
                placeholder={'Enter City'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                inputStyle={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.city}</span>
            </div>
            </div>

          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> State </div>
              <Select
                styles={customStyles}
                name={'state'}
                value={propertyData.state}
                onChange={handleStateChange}
                options={US_STATES}
              />
              <span style={styles.errors}>{errors.state}</span>
            </div>
            <div style={styles.fieldContainer}>
            <div style={{ padding: '10px 0px' }}> Zipcode </div>
               <TextField
                name={'zipcode'}
                text={propertyData.zipcode}
                type={'text'}
                placeholder={'Enter Zip code'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                inputStyle={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
                // onCancel = {} 
                // onFocus = {}
                // onClear = {} 
              />
               <span style={styles.errors}>{errors.zipcode}</span>
            </div>
          </div>

         

          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Price </div>
              <TextField
                name={'price'}
                text={propertyData.price}
                type={'text'}
                placeholder={'Enter Price'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                inputStyle={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.price}</span>
            </div>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Area of Property </div>
              <TextField
                name={'area'}
                text={propertyData.area}
                type={'number'}
                placeholder={'Enter property area in sqft or acres'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                inputStyle={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.area}</span>
            </div>
          </div>

          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Bed </div>
              <TextField
                name={'bed'}
                text={propertyData.bed}
                type={'number'}
                placeholder={'no of beds'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                inputStyle={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.area}</span>
            </div>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Bath </div>
              <TextField
                name={'bath'}
                text={propertyData.bath}
                type={'number'}
                placeholder={'no of baths'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                inputStyle={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.price}</span>
            </div>
          </div>
          
         
          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Description </div>
              <TextField
                name={'remarks'}
                text={propertyData.remarks}
                type={'text'}
                placeholder={'Enter description, like property details, built year...'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                inputStyle={{ color: APP_COLORS.PRIMARY_TEXT,  }}
                autocomplete={"off"}
                multiLine
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.remarks}</span>
            </div>
          </div>

          
        </div> 

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          {isAddingProperty ?
            <><span> {isEdit ? 'Editing' : 'Adding'}  Data </span><Loader /> </> :
            isUploadingImages ?
              <> <span> Uploading Images...</span> <Loader /> </>
              :
              <Button
                label={ (isEdit ? 'Edit' : 'Add') + 'Property'}
                onClick={handleSubmit}
                style={{ textAlign: "center", width: '50%' }}
              />
          }
        </div>
      </div>
      {/* <SRLWrapper elements={lightBoxImage?.images} options={options} /> */}
      <ToastContainer
        position="top-right"
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

const styles = {
   container: {
    height:'100%',
    overflowY:'scroll',
    padding:20,
    boxSizing:'border-box',
    width:'85vw',
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: '20px'
  },
  section: {
    margin: 20,
    padding: 15,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: 30
  },
  fieldContainer: {
    width: '100%',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: 20,
    height: "300px",
    overflowY: 'scroll',
    padding:10,
  },
  errors: {
    padding: 5,
    fontSize: '12px',
    color: 'red',
  },
  
}

