
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    getRealtorProperties, 
    selectRealtorProperties, 
    selectIsFetchingRealtorProperties, 
    selectSearchString, 
    } from "../../store/property"
import { selectUserData } from "../../store/user";
    import PropertyListView from '../common/PropertyListView';

export  default function RealtorLeads ( ){
    const user = useSelector(selectUserData)
    const dispatch = useDispatch()
    let realtorLeads = useSelector(selectRealtorProperties)
    
    let isFetching = useSelector(selectIsFetchingRealtorProperties)
    let searchString = useSelector(selectSearchString)

    useEffect(()=>{
        dispatch(getRealtorProperties( '' , user.id ))
    },[])    
    const onSearchQueryString = (queryString) =>{
        dispatch(getRealtorProperties(queryString, user.id))
    }

    return (<div>
         {realtorLeads && 
            <PropertyListView
              data={realtorLeads}
              isFetching = {isFetching}
              onSearchEnter = {onSearchQueryString}
              searchString = {searchString}
              user = {user}
            /> }
    </div>)
}