import { createSlice } from '@reduxjs/toolkit';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";

import { getSearchResults, transformData, } from './utils';

import { selectUserData } from './user';
import * as api from '../services/api';

let storage = getStorage()

const initialState = {
  isAddingProperty: false,
  isPropertyAdded: false,
  isUploadingImages: false,

  allProperties: null,
  isFetchingAllProperties: null,
  searchString: null,
  realtorProperties: null,
  isFetchingRealtorProperties: null,

}

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {

    setIsFetchingAllProperties(state, action) {
      state.isFetchingAllProperties = action.payload
    },
    setAllProperties(state, action) {
      state.allProperties = action.payload
    },

    setUploading(state, action) {
      state.isUploadingImages = action.payload
    },
    setIsAddingProperty(state, action) {
      state.isAddingProperty = action.payload
    },
    setPropertyAdded(state, action) {
      state.isPropertyAdded = action.payload;
    },
    onPropertyAdded(state, action) {
      let { data } = action.payload;
      let allProperties = state.allProperties || [];
      state.allProperties = [data, ...allProperties];
    },
    statusUpdated(state, action) {
      let { id, dataToEdit } = action.payload;
      let properties = [...state.allProperties];
      let index = properties.findIndex(p => p.id == id)
      properties[index] = {
        ...properties[index],
        ...dataToEdit
      }
      state.allProperties = properties
    },

    onPropertyEdited(state, action) {
      let { index, dataToEdit } = action.payload;
      let properties = [...state.allProperties];
      properties[index] = {
        ...properties[index],
        ...dataToEdit
      }
      state.allProperties = properties
    },
    setSearchString(state, action) {
      state.searchString = action.payload
    },
    setIsFetchingRealtorProperties(state, action) {
      state.isFetchingRealtorProperties = action.payload
    },
    setRealtorProperties(state, action) {
      state.realtorProperties = action.payload
    }
    // setListedProperties(){

    // },
    // setSoldProperties(){

    // },
    // onPropertyListed(){

    // },
    // onPropertySold(){

    // },

  }
})
export default propertySlice.reducer


export const {
  setIsFetchingAllProperties,
  setAllProperties,
  setSearchString,
  onPropertyAdded,
  setUploading,
  setIsAddingProperty,
  setPropertyAdded,
  onPropertyEdited,
  statusUpdated,

  setRealtorProperties,
  setIsFetchingRealtorProperties,


} = propertySlice.actions

export const addProperty = (data) => async (dispatch, getState) => {

  let newQueryId = await api.getDocIdBeforeAdd({ collectionName: 'properties' });
  console.log(newQueryId.id);

  let user = selectUserData(getState());

  const promises = [];
  dispatch(setUploading(true));

  let images = data.imageFiles;
  if (images && images.length) {
    data.images = []
    images.forEach((file) => {
      let fileName = file.name.split(' ').join('') + Date.now().toString();
      let fileRef = `propertyImages/${newQueryId}/${fileName}`;
      const storageRef = ref(storage, fileRef);
      promises.push(uploadBytes(storageRef, file).then(
        async (snapshot) => {
          try {
            const url = await getDownloadURL(snapshot.ref);
            console.log(url);
            data.images.push(url)
            return { url }
          } catch (e) {
            return console.log(e);
          }
        }
      ))
    })
    delete data.imageFiles
    delete data.previewImages
  }

  Promise.all(promises)
    .then((newPromises) => {
      dispatch(setIsAddingProperty(true));
      console.log('All files uploaded', newPromises, promises)
      dispatch(setUploading(false))
      let dataToAdd = {
        id: newQueryId.id,
        created: serverTimestamp(),
        updated: null,
        added_by: {
          name: user.firstname + ' ' + user.lastname,
          role: user.role
        },
        status: 'new',
        new: true,
        available: false,
        pending: false,
        sold: false,
        hot: false,
        ...data,
      }
      console.log(dataToAdd)
      api.setDocById({ collection: 'properties', data: dataToAdd }).then(
        () => {
          dispatch(setIsAddingProperty(false))
          dispatch(setPropertyAdded(true))
          let date = new Date();
          dataToAdd.created = { local: true, time: date };
          let { data } = transformData([dataToAdd]);
          dispatch(onPropertyAdded({ data }))
        })
    }).catch((err) => {
      console.log(err)
    })
}

export const editProperty = (data) => async (dispatch, getState) => {
  let user = selectUserData(getState());

  let properties = getState().property.allProperties
  let index = properties.findIndex(p => p.id == data.id)

  const promises = [];
  dispatch(setUploading(true));

  let images = data.imageFiles;
  if (images && images.length) {
    if (images && images.length) {
      if (data.images.length) {
      } else {
        data.images = []
      }
      images.forEach((file) => {
        let fileName = file.name.split(' ').join('') + Date.now().toString();
        let fileRef = `propertyImages/${data.id}/${fileName}`;
        const storageRef = ref(storage, fileRef);
        promises.push(uploadBytes(storageRef, file).then(
          async (snapshot) => {
            try {
              const url = await getDownloadURL(snapshot.ref);
              console.log(url);
              data.images.push(url)
              return { url }
            } catch (e) {
              return console.log(e);
            }
          }
        ))
      })
      delete data.imageFiles
      delete data.previewImages
    }
  }

  Promise.all(promises)
    .then((newPromises) => {
      dispatch(setIsAddingProperty(true));
      console.log('All files uploaded', newPromises, promises)
      dispatch(setUploading(false))
      let dataToAdd = {
        ...data,
        updated: serverTimestamp(),
      }
      console.log(dataToAdd)
      api.setDocById({ collection: 'properties', data: dataToAdd }).then(
        () => {
          dispatch(setIsAddingProperty(false))
          dispatch(setPropertyAdded(true))
          // let {data} = transformData([dataToAdd]);
          dispatch(onPropertyEdited({ index, dataToAdd }))
        })
    }).catch((err) => {
      console.log(err)
    })

}

export const getAllProperties = (queryString) => async (dispatch, getState) => {
  dispatch(setIsFetchingAllProperties(true))
  let cachedData = getState().property.allProperties || null;
  if (!cachedData || cachedData.length == 0 || queryString != undefined) {
    api.getAllDocs({ collectionName: "properties", order: ('created'), }).then(
      response => {
        console.log(response)
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setSearchString(null))
        }
        dispatch(setIsFetchingAllProperties(false))
        dispatch(setAllProperties(data))
      }
    )
  } else {
    dispatch(setIsFetchingAllProperties(false))
    dispatch(setAllProperties(cachedData))
  }
}


export const getRealtorProperties = (queryString, userId ) => async (dispatch, getState) => {
  dispatch(setIsFetchingRealtorProperties(true))
  let cachedData = getState().property.availableProperties || null;
  if (!cachedData || cachedData.length == 0 || queryString != undefined ) {
    let promises = []

    promises.push(
      api.getAllDocs({ collectionName: "properties", order: ('created'), wheres: [["hot", "==", true], ['assignedTo.id', '==', userId ] ] }).then(
        response => {
          return response
        }
      ).catch( e => {
        console.log(e)
        return null
      })
    )
    promises.push(
      api.getAllDocs({ collectionName: "properties", order: ('created'), wheres: [["available", "==", true], ['assignedTo.id', '==', userId ]] }).then(
        response => {
          return response
        }).catch(e => {
          console.log(e)
          return null
        })
    )


    Promise.all(promises).then((response) => {
      console.log(response)
      if (response) {
        
        response = [...response[0], ...response[1]]
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setSearchString(null))
        }
        console.log(data)
        dispatch(setIsFetchingRealtorProperties(false))
        dispatch(setRealtorProperties(data))
      }
    })

  } else {
    dispatch(setIsFetchingRealtorProperties(false))
    dispatch(setRealtorProperties(cachedData))
  }
}

export const updateStatus = (id, status) => async (dispatch, getState) => {
  console.log(status, id)
  let dataToEdit;
  if (status == 'new') {
    dataToEdit = {
      new: true,
      available: false,
      pending: false,
      sold: false,
      hot: false,
      status: status,
      id
    }
  } else if (status == 'available') {
    dataToEdit = {
      available: true,
      new: false,
      pending: false,
      sold: false,
      hot: false,
      status: status,
      id
    }
  } else if (status == 'pending') {
    dataToEdit = {
      pending: true,
      new: false,
      available: false,
      sold: false,
      hot: false,
      status: status,
      id
    }
  } else if (status == 'sold') {
    dataToEdit = {
      sold: true,
      new: false,
      available: false,
      pending: false,
      hot: false,
      status: status,
      id
    }
  }
  else if (status == 'hot') {
    dataToEdit = {
      hot: true,
      sold: false,
      new: false,
      available: false,
      pending: false,
      status: status,
      id
    }
  }
  console.log(dataToEdit)
  api.setDocById({ collection: 'properties', data: dataToEdit, }).then(
    res => {
      dispatch(statusUpdated({ id, dataToEdit }))
    }
  )
}

export const assignPropertyToUser = (userId, propertyId) => async (dispatch, getState) => {
    let isDelete = userId == 'none' ? true : false
    
    let allUsers = getState().users.allUsers
    let assignToUser = allUsers.find( user => user.id == userId)
    let allProperties = getState().property.allProperties
    let index = allProperties.findIndex( p => p.id == propertyId)

    let data = {
      id:propertyId,
      assignedTo: isDelete ? null : {
        id: userId, 
        name : assignToUser.firstname + ' ' + assignToUser.lastname
      }
    }
    await api.setDocById({collection:'properties', data:data })
    dispatch(onPropertyEdited({index, dataToEdit:data}))

}
 


export const selectIsUploadingImages = (state) => state.property.isUploadingImages;
export const selectIsAddingProperty = (state) => state.property.isAddingProperty;
export const selectIsPropertyAdded = (state) => state.property.isPropertyAdded;


export const selectAllProperties = (state) => state.property.allProperties;
export const selectIsFetchingAllProperties = (state) => state.property.isFetchingAllProperties;
export const selectSearchString = (state) => state.property.searchString;

export const selectRealtorProperties = (state) => state.property.realtorProperties;
export const selectIsFetchingRealtorProperties = (state) => state.property.isFetchingRealtorProperties;
