import {DateTime} from 'luxon';

export const  validate = (name, value) => {
  //A function to validate each input values
  let errors = {
      password:null,
      address:null,
      state:null,
      city:null,

      name:null,
      email:null,
      number:null,
      type:null,

  };
  switch (name) {
      case 'password':
          if(
              !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
          ){  
            errors.password = 'Password should contains atleast 8 charaters and containing uppercase,lowercase and numbers'
          }else{
            errors.password = null;
          }
      break;

      case 'name':
        if(value.length == 0){
          errors.name = 'Add a name!';
        }else{
          errors.name = null
        }
      break;
      
      case 'email':
            if(
                !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
            ){
               errors.email = 'Enter a valid email address!'
            }else{
               errors.email = null;
            }
      break;
      
      case 'number':
        if(value.length == 0){
          errors.number = 'Add a number!';
        }else{
          errors.number = null
        }
      break;

      
      case 'address' : 
          if(value.length == 0){
              errors.address = 'Add an address, can not be empty!'
          }
         break;

      case 'state' : 
          if(value.length == 0 ) {
            errors.state = 'Add a state!'

          }
      case 'city' : 
          if(value.length == 0 ) {
            errors.city = 'Add a city!'
          }
      case 'price' : 
            if(value.length == 0){
                errors.price = 'Add a price.'
            }
        break;

      default:
          break;    
  }
  return errors;
}

export const checkForErrors = (errors) => {

    for(let key in errors){
        if (errors[key]) {
           return true
        }
    }
    return false;
}

export const getUsTimestamp = (jsDate) => {
  return DateTime.fromJSDate(jsDate, {zone:'America/New_York'})
}

export const getLocaleUSDate = (jsDate) => {

  if(jsDate)
  {return jsDate.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'America/New_York',
  })}else{
    return ''
  }
}


export const US_STATES = [
    {
      value: "Alabama",
      label: "Alabama",
      abbr: "AL"
    },
    {
      value: "Alaska",
      label: "Alaska",
      abbr: "AK"
    },
    {
      value: "American Samoa",
      label: "American Samoa",
      abbr: "AS"
    },
    {
      value: "Arizona",
      label: "Arizona",
      abbr: "AZ"
    },
    {
      value: "Arkansas",
      label: "Arkansas",
      abbr: "AR"
    },
    {
      value: "California",
      label: "California",
      abbr: "CA"
    },
    {
      value: "Colorado",
      label: "Colorado",
      abbr: "CO"
    },
    {
      value: "Connecticut",
      label: "Connecticut",
      abbr: "CT"
    },
    {
      value: "Delaware",
      label: "Delaware",
      abbr: "DE"
    },
    {
      value: "District Of Columbia",
      label: "District Of Columbia",
      abbr: "DC"
    },
    {
      value: "Federated States Of Micronesia",
      label: "Federated States Of Micronesia",
      abbr: "FM"
    },
    {
      value: "Florida",
      label: "Florida",
      abbr: "FL"
    },
    {
      value: "Georgia",
      label: "Georgia",
      abbr: "GA"
    },
    {
      value: "Guam",
      label: "Guam",
      abbr: "GU"
    },
    {
      value: "Hawaii",
      label: "Hawaii",
      abbr: "HI"
    },
    {
      value: "Idaho",
      label: "Idaho",
      abbr: "ID"
    },
    {
      value: "Illinois",
      label: "Illinois",
      abbr: "IL"
    },
    {
      value: "Indiana",
      label: "Indiana",
      abbr: "IN"
    },
    {
      value: "Iowa",
      label: "Iowa",
      abbr: "IA"
    },
    {
      value: "Kansas",
      label: "Kansas",
      abbr: "KS"
    },
    {
      value: "Kentucky",
      label: "Kentucky",
      abbr: "KY"
    },
    {
      value: "Louisiana",
      label: "Louisiana",
      abbr: "LA"
    },
    {
      value: "Maine",
      label: "Maine",
      abbr: "ME"
    },
    {
      value: "Marshall Islands",
      label: "Marshall Islands",
      abbr: "MH"
    },
    {
      value: "Maryland",
      label: "Maryland",
      abbr: "MD"
    },
    {
      value: "Massachusetts",
      label: "Massachusetts",
      abbr: "MA"
    },
    {
      value: "Michigan",
      label: "Michigan",
      abbr: "MI"
    },
    {
      value: "Minnesota",
      label: "Minnesota",
      abbr: "MN"
    },
    {
      value: "Mississippi",
      label: "Mississippi",
      abbr: "MS"
    },
    {
      value: "Missouri",
      label: "Missouri",
      abbr: "MO"
    },
    {
      value: "Montana",
      label: "Montana",
      abbr: "MT"
    },
    {
      value: "Nebraska",
      label: "Nebraska",
      abbr: "NE"
    },
    {
      value: "Nevada",
      label: "Nevada",
      abbr: "NV"
    },
    {
      value: "New Hampshire",
      label: "New Hampshire",
      abbr: "NH"
    },
    {
      value: "New Jersey",
      label: "New Jersey",
      abbr: "NJ"
    },
    {
      value: "New Mexico",
      label: "New Mexico",
      abbr: "NM"
    },
    {
      value: "New York",
      label: "New York",
      abbr: "NY"
    },
    {
      value: "North Carolina",
      label: "North Carolina",
      abbr: "NC"
    },
    {
      value: "North Dakota",
      label: "North Dakota",
      abbr: "ND"
    },
    {
      value: "Northern Mariana Islands",
      label: "Northern Mariana Islands",
      abbr: "MP"
    },
    {
      value: "Ohio",
      label: "Ohio",
      abbr: "OH"
    },
    {
      value: "Oklahoma",
      label: "Oklahoma",
      abbr: "OK"
    },
    {
      value: "Oregon",
      label: "Oregon",
      abbr: "OR"
    },
    {
      value: "Palau",
      label: "Palau",
      abbr: "PW"
    },
    {
      value: "Pennsylvania",
      label: "Pennsylvania",
      abbr: "PA"
    },
    {
      value: "Puerto Rico",
      label: "Puerto Rico",
      abbr: "PR"
    },
    {
      value: "Rhode Island",
      label: "Rhode Island",
      abbr: "RI"
    },
    {
      value: "South Carolina",
      label: "South Carolina",
      abbr: "SC"
    },
    {
      value: "South Dakota",
      label: "South Dakota",
      abbr: "SD"
    },
    {
      value: "Tennessee",
      label: "Tennessee",
      abbr: "TN"
    },
    {
      value: "Texas",
      label: "Texas",
      abbr: "TX"
    },
    {
      value: "Utah",
      label: "Utah",
      abbr: "UT"
    },
    {
      value: "Vermont",
      label: "Vermont",
      abbr: "VT"
    },
    {
      value: "Virgin Islands",
      label: "Virgin Islands",
      abbr: "VI"
    },
    {
      value: "Virginia",
      label: "Virginia",
      abbr: "VA"
    },
    {
      value: "Washington",
      label: "Washington",
      abbr: "WA"
    },
    {
      value: "West Virginia",
      label: "West Virginia",
      abbr: "WV"
    },
    {
      value: "Wisconsin",
      label: "Wisconsin",
      abbr: "WI"
    },
    {
      value: "Wyoming",
      label: "Wyoming",
      abbr: "WY"
    }
  ]