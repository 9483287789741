import { configureStore , getDefaultMiddleware} from '@reduxjs/toolkit';
import usersReducer from './user';
import propertyReducer from './property';


export default configureStore({
  reducer: {
    users: usersReducer,
    property: propertyReducer
  },
  middleware: [...getDefaultMiddleware({immutableCheck: false, serializableCheck: false,})]
});
