import { useEffect, useState } from "react"
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai"
import { useDispatch,useSelector } from "react-redux"
import {  } from "react-redux"
import { getAllUsers, selectAllUsers , updateUserPlan } from "../../store/user"
import { APP_COLORS } from "../../styles";
import { showMenu } from "../common/RootMenu";

export default function AdminDash ( ){

    const dispatch = useDispatch()
    let allUsers = useSelector(selectAllUsers)

    let [showFullView, setShowFullView] = useState(null)

    useEffect(()=>{
        dispatch(getAllUsers())
    },[])

    console.log(allUsers)

    return (
        <div style={{padding:20,}}>
            <div style={{fontWeight:'bolder', fontSize:'18px', letterSpacing:1.2}}> All Users  </div> 

            {allUsers ? 
            <div style={{
                background: APP_COLORS.META_BACKGROUND, 
                marginTop:20, 
                padding:20,
                }}> 
            <div style={styles.panelHeader}>
            <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                    Sr
                </div>
                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                    Full Name
                </div>
                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                    Email
                </div>
                <div style={{ padding: '10px 5px', outline: '1px solid white', }}>
                    Status
                </div>
             </div>              
            {allUsers.length ? allUsers.map( (user,i) => {
                let menuOptions =  [{
                    title: null,
                    items: [
                        {
                            label: 'Activate Plan',
                            id: 'activate_plan',
                            disabled: user.isActivated
                        },
                        {
                            label: 'Deactivate Plan',
                            id: 'deactivate_plan',
                            disabled: !user.isActivated
                        },
                    ]
                  }]   
                let isActive = !!showFullView && showFullView.index == i
                  return ( 
                  <div style={{background : isActive && APP_COLORS.PAGE_BACKGROUND}} >
                  <div style={styles.panelRow}>
                    <div style={{alignSelf:'center', padding :'0px 5px', display:'flex', alignItems:'center', gap:10}}>
                    {isActive ?
                        <AiFillCaretUp 
                        size={22} 
                        color={'green'}
                        onClick={()=>setShowFullView(null)} /> 
                        :
                        <AiFillCaretDown 
                        size={22} 
                        onClick={()=>setShowFullView({index:i, show:true })} /> 
                    } 
                        
                    { i+1} 
                    </div>
                    <div style={{alignSelf:'center', padding :'0px 5px'}}>
                    {user.firstname +' '+ user.lastname} 
                    </div>
                    <div style={{alignSelf:'center', padding :'0px 5px'}}>
                    {user.email}
                    </div>
                    <div style={{
                        alignContent:'center', 
                        background: user.isActivated ? '#fcc938' : '#ccc',
                        padding:'10px 5px',
                        display:'flex',
                        justifyContent:'space-around',

                        }}>
                        <div style={{width:'200px'}}>{user.isActivated ? 'Active' : 'Inactive' } </div>
                        <IoMdArrowDropdownCircle  
                         style={{
                            cursor:'pointer', 
                            color:APP_COLORS.THEME_GRAY, 
                            fontSize:'20px',
                            color: user.isActivated ? '#000' : APP_COLORS.THEME_GRAY
                        }}    
                         onClick={(e)=>{ 
                            console.log(e) 
                            menuOptions && showMenu({
                            position: e.target.getBoundingClientRect(),
                            onItemClick: (optionId) => {
                            dispatch(updateUserPlan(user, optionId))
                        },
                        menuOptions,
                        })}}
                        />
                    </div>
                </div>
                {isActive && 
                    <div style={{
                        boxSizing:'border-box',
                        width:'100%', 
                        maxHeight:'200px',
                        padding:10, 
                        position:'relative',
                        display:'flex',
                        gap:20
                        }}>
                    
                    <div style={{width:'50%',}}>
                    <div style={{marginBottom:10, fontWeight:'bold'}}> Zipcodes </div>
                    <div style={{display:'flex',  gap:10, flexWrap:'wrap',  }}> 
                        {user.zipcodes && user.zipcodes.map((zip => 
                        <div style={{padding:6, background:APP_COLORS.META_BACKGROUND, borderRadius:4 }}> 
                            {zip} 
                        </div> ))}
                    </div>
                    </div>

                    <div style={{width:'50%',}}>
                    <div style={{marginBottom:10, fontWeight:'bold'}}> Info </div>
                        <div style={{display:'flex', justifyContent:'space-between', padding:'5px 0px'}}> 
                        <div style={{fontWeight:'bold'}}> Cell </div>
                            <div> {user.cell} </div>  
                        </div>

                        <div style={{display:'flex', justifyContent:'space-between',padding:'5px 0px'}}> 
                        <div style={{fontWeight:'bold'}}> Address </div>  
                            {user.address && <div> {user.address.street}, {user.address.city}, {user.address.state}. {user.address.zip} </div>}
                        </div>

                        <div style={{display:'flex', justifyContent:'space-between',padding:'5px 0px'}}> 
                        <div style={{fontWeight:'bold'}}> Company </div>  
                            <div> {user.company} </div>  
                        </div>

                        
                    </div>
                    
                    </div> 
                    }
                </div>
                  )}
                  
            )  : null } </div> : null}
        </div>
    )
}


const styles = {
    panelHeader: {
        display: 'grid',
        background: APP_COLORS.BUTTON_BACKGROUND,
        color: "white",
        fontWeight: 'bold',
        gridTemplateColumns: ' minmax(30px, 1fr) minmax(200px, 1.67fr) minmax(200px, 3fr) minmax(100px, 1.67fr) ',
        gap: 2,
        alignItems: 'end',
        
    },
    panelRow:{
        display: 'grid',
        padding:'5px 0px',
        boxSizing:"border-box",
        height:"50px",
        gridTemplateColumns: ' minmax(30px, 1fr) minmax(200px, 1.67fr) minmax(200px, 3fr) minmax(100px, 1.67fr) ',
        gap:2,
    }
}