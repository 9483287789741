import { useState } from 'react'
import { APP_COLORS } from "../../styles"
import { NavLink } from "react-router-dom"
import { ADD_PROPERTY_ROUTE, ALL_PROPERTIES_ROUTE, DASHBOARD_PAGE_ROUTE, LEADS_ROUTE, LISTED_PROPERTY_ROUTE, REALTOR_HOME_ROUTE, SOLD_PROPERTY_ROUTE } from "../../routes"

import {  FaClipboardCheck, FaHandshake, FaHome, FaPlusCircle } from "react-icons/fa";
import { MdMapsHomeWork } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../store/user';
import { ROLES } from '../../constants';



export default function Sidebar() {
    let user = useSelector(selectUserData)

    return (
        <div style={styles.sidebarContainer}>
            <NavLink
                to={DASHBOARD_PAGE_ROUTE}
            >
                {({ isActive }) => (
                    <div
                        className='hover'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 20,
                            gap: 10,
                            fontWeight: 'bold',
                            borderBottom: `1px solid ${APP_COLORS.BUTTON_BACKGROUND}`,
                            borderTop: `1px solid ${APP_COLORS.BUTTON_BACKGROUND}`,
                            color: isActive ? APP_COLORS.BUTTON_BACKGROUND : APP_COLORS.PRIMARY_TEXT,
                            fontSize: '20px',
                        }}>
                        <FaHome /> Home
                    </div>
                )}

            </NavLink>

            <ul style={styles.list}>
                {user?.admin && 
                    <>
                     <NavLink
                    className='hover'
                    to={ADD_PROPERTY_ROUTE}>

                    {({ isActive }) => (
                        <li style={styles.listItem(isActive)} className="hover" >
                            <FaPlusCircle style={{ marginRight: '10px', }} /> Add Property
                        </li>
                    )}
                </NavLink>
                <NavLink
                    className='hover'
                    to={ALL_PROPERTIES_ROUTE}>
                    {({ isActive }) => (
                        <li style={styles.listItem(isActive)} className="hover" >
                            <MdMapsHomeWork style={{ marginRight: '10px', }} /> All Properties
                        </li>
                    )}
                </NavLink>
                    </>
                }
                
                {/* <NavLink
                    className='hover'
                    to={LISTED_PROPERTY_ROUTE}>
                    {({ isActive }) => (
                        <li style={styles.listItem(isActive)} className="hover" >
                            <FaClipboardCheck style={{ marginRight: '10px', }} /> Available
                        </li>
                    )}
                </NavLink>

                <NavLink
                    className='hover'
                    to={SOLD_PROPERTY_ROUTE}>
                    {({ isActive }) => (
                        <li style={styles.listItem(isActive)} className="hover" >
                            <FaHandshake style={{ marginRight: '10px' }} color={isActive ? APP_COLORS.BUTTON_BACKGROUND : APP_COLORS.PRIMARY_TEXT} />
                            Sold Properties
                        </li>
                    )}

                </NavLink> */}

                {user.realtor && 
                <NavLink
                    className='hover'
                    to={LEADS_ROUTE}>
                    {({ isActive }) => (
                    <li style={styles.listItem(isActive)} className="hover" >
                        <MdMapsHomeWork style={{ marginRight: '10px', }} /> Your Leads 
                    </li>
                     )}
                    </NavLink>
                }
                {/* {user.realtor &&
                    <div style={{padding:10,background: APP_COLORS.BUTTON_BACKGROUND, color:APP_COLORS.SECONDARY_TEXT}}>
                        To inquire about any property please call the following numbers.  <br/>
                        Mention the Property Code when you talk to our agents.
                        <br/>
                        <br/>
                        <div>
                        Alex Monroe
                        <br/>
                        +1 (813) 445 5816
                        </div>
                        <br/>
                        <div>
                        Zack Murphy
                        <br/>
                        +1 (904) 267-3764
                        </div>
                        <br/>
                        <div>
                        James Anderson
                        <br/>
                        +1 (904) 606 3337
                        </div>

                        </div>
                } */}
            </ul>
        </div>
    )
}

const styles = {
    sidebarContainer: {
        minWidth: '15vw',
        height: 'calc(100vh - 50px)',
        background: APP_COLORS.META_BACKGROUND,
    },
    list: {
        padding: '0px 0px',
        marginTop: "10px",
    },
    listItem: (isActive) => ({
        display: 'flex',
        alignItems: 'center',
        listStyle: 'none',
        padding: '10px 20px',
        textDecoration: 'none',
        color: isActive ? APP_COLORS.BUTTON_BACKGROUND : APP_COLORS.PRIMARY_TEXT,
    })

}
