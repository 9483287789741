import { APP_COLORS } from "../../styles";
import {AiFillFire} from 'react-icons/ai';
import Carousel from './Carousel';

// function useScrollTop() {
//     const [scrollTop, setScrollTop] = useState(0);
//     const onScroll = (event) => setScrollTop(event.target.scrollTop);
//     return [scrollTop, { onScroll }];
//   }

export default function PropertyCard({item, index, onOpenImageGallery, showFullView}){
    
    // const [scrollTop, scrollProps] = useScrollTop();

    return(
        <div 
        onClick={()=> showFullView({item, show:true})}
        style={{
            cursor:'pointer',
            height:'320px', 
            borderRadius:'5px', 
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            color:APP_COLORS.PRIMARY_TEXT
            }}> 
        <div style={{
                height:"180px",
                position:'relative',
                }}>
             <div style={{position:'absolute',fontWeight:"bold", right:4, top:4, zIndex:4, background:'white', padding:'2px 5px', borderRadius:10}}>
                {item.city.slice(0,1)+'-'+item.state.abbr+'-'+ (index+1) }
            </div>
            {item.hot && 
            <div style={{position:'absolute',fontWeight:"bold", left:4, top:4, zIndex:2, background:'white', padding:'1px', borderRadius:'50%'}}>
            <AiFillFire size={20} color={'crimson'}/>
            </div>
            }
                <div style={{ width:'100%', height:'100%'}}  >
                <img src={item.images.length && item.images[0]} style={{  width:'100%', height:'100%', borderTopLeftRadius:'5px', borderTopRightRadius:'5px', objectFit:"cover" }} />
                </div>
                
            {/* <Carousel
                images={item.images}
                onOpenImageGallery = {onOpenImageGallery}
                navigation = {true}
            /> */}
            </div>

            <div style={{
                display:'flex', 
                flexDirection:'column', 
                padding:'15px 10px 2px 10px', 
                boxSizing:'border-box',
                gap:8,
                }}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                <div style={{fontSize:'22px', fontWeight:'bold', color:APP_COLORS.BUTTON_BACKGROUND}}>${item.price}</div> 
                <div style={{fontSize:'12px'}}>{item.dateStr}</div>
                </div>

                <div style={{fontWeight:'bold', }}>{item.address},  {item.city} , {item.state.label} {item.zipcode} </div>

                <div style={{display:'flex', justifyContent:'space-between'}}>
                <div style={{fontSize:'12px'}}> {item.area} sqft </div>
                <div style={{fontSize:'12px'}}> {item.bed} bed | {item.bath} bath  </div>
                </div>
                {/* <div 
                {...scrollProps}
                style={{
                    boxShadow:  scrollTop > 0 ? "inset 0 8px 5px -7px rgb(0 0 0 / 0.4)" : "none",
                    transition: "box-shadow 0.3s",
                    fontSize:'12px', 
                    overflow:'scroll', 
                    textOverflow:'ellipsis', 
                    whiteSpace:'pre-wrap',
                }}
                >
                    {item.remarks}
                </div> */}
            </div>
            
        </div>
    )
}