export default function Loading(){
    return(
      <div style={styles.spinnerStyles}></div>
    )
  }
  
  const styles = {
    spinnerStyles:{
      margin:'0 auto',
      width: 30,
      height: 30,
      borderRadius: 30,
      border: '1px solid rgba(0,0,0,0)',
      borderRight: '3px solid #aaa',
      animation: 'spin 1s infinite linear',
      display: 'inline-block',
      }
  }