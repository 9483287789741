
import { FaUser, FaUserCircle, FaUserTie } from "react-icons/fa"
import { HiUserCircle } from "react-icons/hi"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectUserData } from "../../store/user"
import { APP_COLORS } from "../../styles"
import Button from "../common/Button"

export default function ProfileCard () {
    let userData = useSelector(selectUserData)
    let navigate = useNavigate()
    return (
    <div style={{}}>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <div style={{fontSize:24, fontWeight:'bolder', color:APP_COLORS.BUTTON_BACKGROUND}}>
            {userData.firstname ? userData.firstname + ' ' + userData.lastname : userData.email }
        </div>
        <div style={{
            background:APP_COLORS.PAGE_BACKGROUND, 
            width:100, 
            height:100, 
            display:'flex', 
            justifyContent:'center',
            alignItems:'center'
            }}>
        <FaUserTie size={80} color={APP_COLORS.BUTTON_BACKGROUND}/>
        </div>
        </div>

        <div style={{
            display:'flex', 
            flexDirection:'column', 
            gap:10, 
            marginTop:30,
            color: APP_COLORS.PRIMARY_TEXT
            }}>
            <div>
                <div style={styles.title}>
                    Email
                </div>
                <div style={{padding:'10px 0px'}}>
                    {userData.email  }
                </div>
            </div>
            
            <div>
                <div style={styles.title}>
                    BIO
                </div>
                <div style={{padding:'10px 0px'}}>
                    {userData.bio ? userData.bio : 'Add a bio' }
                </div>
            </div>
            <div>
            <div style={styles.title}>
                    COMPANY
                </div>
                <div style={{padding:'10px 0px'}}> {userData.company ? userData.company : 'Add a company' } </div>
            </div>
            <div>
            <div style={styles.title}>
                    Address
                </div>
                <div style={{padding:'10px 0px'}}> {userData.address ? (userData.address.street + ', ' + userData.address.city + ', ' + userData.address.state + ', ' + userData.address.zip)  : 'Add an address' } </div>
            </div>
            

        </div>

        {!userData.firstname ? <div style={{display:'flex',justifyContent:'center', }}>
            <Button
                onClick={()=>{navigate('/dashboard/profile')}}
                style={{
                    background:'inherit', 
                    color:APP_COLORS.BUTTON_BACKGROUND,
                    width:'200px',
                    fontSize:'16px',
                    border:`1px solid ${APP_COLORS.BUTTON_BACKGROUND}`, 
                }} 
                label={'Complete Profile'} />
        </div> : null }

    </div>)
}



const styles = {
    title:{fontWeight:'bold', textTransform:'uppercase' }
}