import React, { useCallback , useEffect} from 'react';
import { useDropzone } from 'react-dropzone'
import '../../App.css';
import { APP_COLORS } from '../../styles';

export default function FileUploader({onFilesDropped, text }) {

  useEffect(()=>{
    document.onpaste = function(event){
      var items = (event.clipboardData || event.originalEvent.clipboardData).items;
      let filesFromClipBoard = []
      for (let index in items) {
        var item = items[index];
        if (item.kind === 'file') {
          // adds the file to your dropzone instance
          console.log(item.getAsFile())
          filesFromClipBoard.push(item.getAsFile())
        }
      }
      onDrop(filesFromClipBoard)
    }
  },[])
  
  const onDrop =  useCallback(( acceptedFiles ) => {
    onFilesDropped(acceptedFiles)
  },[])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop ,  accept: 'image/*' })

  return (
      <div style={styles.containerStyle} {...getRootProps()}>
      <input onPaste={(e)=>{console.log(e)}} {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>{text}</p>
      )}
    </div>)
}


const styles = {
  containerStyle : {
  border:`1px solid  ${APP_COLORS.BUTTON_BACKGROUND}`,
  width: 250,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 50,
  textAlign: 'center',
}
}