import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { APP_COLORS } from "../styles";
import TextField from '../components/common/TextField';
import Loading from '../components/common/Loading';
import Button from '../components/common/Button';
import logo from '../assets/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignUpDialog from '../pages/SignupPage';


import { login, selectIsLogginIn, selectLoginError, selectIsLoggedIn } from '../store/user';

function Loginpage() {
  let bg1 = "https://home-dealers.mo.cloudinary.net/bgs/bg5.jpg"
  const width = window.innerWidth;
  const isMobile = width < 700;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let isLoggingIn = useSelector(selectIsLogginIn);
  let loginError = useSelector(selectLoginError);
  let isLoggedIn = useSelector(selectIsLoggedIn);
  let [signup, showSignup] = useState(false)

  let [formData, setFormData] = useState({
    email: '',
    password: '',
    errors: {}
  })

  const handleChange = (target) => {
    const { name, value } = target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    })
    )
  }

  const handleSubmit = () => {
    dispatch(login(formData))
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    }
    if (!isLoggedIn && loginError) {
      toast.error(loginError.code)
    }
  }, [isLoggedIn, loginError])

  return (
    <div style={styles.container(bg1)}>
      <form style={styles.formContainer(isMobile)}>
        <div className={'logo-rotate'} style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ maxWidth: '200px', }}>
            <Link to='/'>
              <img src={logo} style={{ width: '100%', width: '100%' }} />
            </Link>
          </div>

        </div>

        <div style={styles.formHeader}>
          Sign into your account.
        </div>
        <section style={styles.formBody(isMobile)}>
          <TextField
            name={'email'}
            text={formData.email}
            placeholder={'email'}
            onEnter={handleSubmit}
            // onCancel = {} 
            containerStyle={{ marginBottom: 5, borderRadius: 5 }}
            onTextChange={handleChange}
            autoFocus={true}
          // onFocus = {}
          // onClear = {} 
          />
          <TextField
            name={'password'}
            text={formData.password}
            type={'password'}
            placeholder={'password'}
            onEnter={handleSubmit}
            // onCancel = {} 
            containerStyle={{ marginBottom: 5, borderRadius: 5 }}
            onTextChange={handleChange}
            autoFocus={true}
          // onFocus = {}
          // onClear = {} 
          />

          <div style={{ marginTop: 5 }}>
            {isLoggingIn ?
              <Loading />
              :
              <div 
                onClick={handleSubmit}
                style={{width:'50%'}} className='button-hover'> Log in </div>
              // <Button
              //   label={'Log in'}
              //   style={{ padding: '10px 30px', fontSize: '20px', borderRadius: '5px' }}
              //   onClick={handleSubmit}
              // />
            }</div>


          <div style={{ margin: '10px 0px' }}>or</div>

            
          <div style={{ display: 'flex', justifyContent: 'center', }}>

            <div 
              onClick={() => { showSignup(true) }}
              style={{ 
                padding: '10px 30px', 
                fontSize: '16px', 
                borderRadius: '5px', 
                background: '#31a54f'
                 }} className='button-hover'> Register with us  </div>
            
          </div>


        </section>
        <section style={styles.formFooter}>
          Business Buddy © 2024
        </section>
      </form>

      <ToastContainer
        position="top-right"
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {signup && <SignUpDialog onClose={() => showSignup(false)} />}
    </div>
  )
}
export default Loginpage;


const styles = {
  container: (pic) => ({
    padding: 25,
    paddingTop: 50,
    boxSizing: 'border-box',
    color: APP_COLORS.PRIMARY_TEXT,
    minHeight: '100vh',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${pic}) `,  /* fallback for old browsers */
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
    // background: '-webkit-linear-gradient(to right, #4CA1AF, #2C3E50)',  /* Chrome 10-25, Safari 5.1-6 */
    // background: 'linear-gradient(to right, #4CA1AF, #2C3E50)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }),
  formContainer: (isMobile) => ({
    width: isMobile ? "100%" : "40%",
    padding: isMobile ? "10px" : "30px",
    borderRadius: 8,
    background: APP_COLORS.PAGE_BACKGROUND,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',

  }),
  formHeader: {
    fontSize: '20px',
    padding: '10px',
  },
  formBody: (isMobile) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: isMobile ? '10px 20px' : '20px 40px',
  }),
  formFooter: {
    marginTop: '10px',
    fontSize: '11px',
    padding: 20,
    borderTop: `1px solid ${APP_COLORS.SECONDARY_TEXT}`,

  }
}
