import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import {getStorage } from "firebase/storage"
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyAPNwEMqhilxSk31XRQ_ml4eJID7nuQ8Eo",
    authDomain: "home-dealers-e0bc4.firebaseapp.com",
    projectId: "home-dealers-e0bc4",
    storageBucket: "home-dealers-e0bc4.appspot.com",
    messagingSenderId: "297330812033",
    appId: "1:297330812033:web:514536526917b847d75980"
};

export const app = initializeApp(firebaseConfig);
export const auth  = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app)







