import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllProperties, selectAllProperties, selectIsFetchingAllProperties, selectSearchString } from "../../store/property"
import { selectAllActiveUsers, selectUserData } from "../../store/user";
import { APP_COLORS } from "../../styles";
import PropertyListView from '../common/PropertyListView';

export default function AllProperties() {
    const dispatch = useDispatch()
    let user = useSelector(selectUserData)
    let allProperties = useSelector(selectAllProperties)
    let isFetching = useSelector(selectIsFetchingAllProperties)
    let searchString = useSelector(selectSearchString)
    let activeUsers = useSelector(selectAllActiveUsers)

    useEffect(() => {
        dispatch(getAllProperties())
    }, [])


    const onSearchQueryString = (queryString) => {
        dispatch(getAllProperties(queryString))
    }

    console.log(activeUsers)

    return (
        <div style={styles.container}>
            {allProperties &&
                <PropertyListView
                    data={allProperties}
                    isFetching={isFetching}
                    onSearchEnter={onSearchQueryString}
                    searchString={searchString}
                    user={user}
                    activeUsers={activeUsers}
                />}
        </div>
    )
}

const styles = {
    container: {
        position: 'relative',
        height: '100%',
        overflowY: 'scroll',
        padding: '0px 0px',
        paddingRight: 1,
        boxSizing: 'border-box',
        width: '85vw',
    }
}