import { app, db , auth,  storage} from './config';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import {collection, query, where, getDocs, doc, setDoc,  getDoc, orderBy , arrayUnion, increment} from "firebase/firestore";


export async function authenticateUser(data) {
  let {email, password} = data;

  return signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {   
    console.log(userCredential) 
    return {user:userCredential.user}
  })
  .catch((error) => {
    console.log(error)
    const errorCode = error.code;
    const errorMessage = error.message;
    return {error:{code:errorCode,message:errorMessage}}
  })
}

export async function logout(){
  auth.signOut();
}

export const registerWithEmailAndPassword = async (userCredentials) => {
  let {email, password  } = userCredentials;
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    console.log(res)
    return {
      id: user.uid,
      email,
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


export async function getDocById({table, id }){
  const docRef = doc(db, table, id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

export async function getDocIdBeforeAdd({collectionName}){
  return  doc(collection(db, collectionName));
}

export async function setDocById({collection,data}) {
  const ref = doc(db, collection, data.id);
  await setDoc(ref, data, {merge:true} );
}

export async function updateCountById({collection,id, field}) {
  const ref = doc(db, collection, id);
  await setDoc(ref, 
    {
      [field]: increment(1)
    }, 
    {merge:true});
}


export async function getAllDocs ({collectionName, wheres, order, limit}){
  let querySnapshot; 
  let whereConditions = []
  
  if(!!wheres){
    for(let i = 0; i< wheres.length ; i++){
      let wher = wheres[i]
      whereConditions.push( where( wher[0], wher[1], wher[2]) )
    }
    const q = query(collection(db, collectionName), ...whereConditions ,  orderBy(order,'desc'));
    querySnapshot = await getDocs(q)
  }else{
    const q = query(collection(db, collectionName), orderBy(order,'desc'));
    querySnapshot = await getDocs(q)
  }
  let data = []
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    data.push(doc.data())
  });
  return data;
}





