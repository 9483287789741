import React, { useEffect } from 'react'

import { render, unmountComponentAtNode } from 'react-dom'
import OptionsMenu from './OptionsMenu';

const ITEM_ID = 'react-root-menu';
let getDomElement = () => document.getElementById(ITEM_ID)

export function showMenu(properties) {
  let divTarget = getDomElement()
  if (!divTarget) {
    // document.body.children[0].classList.add('menu')
    divTarget = document.createElement('div')
    divTarget.id = ITEM_ID
    document.body.appendChild(divTarget)
  }
  render(<RootMenu {...properties} />, divTarget)
}
const RootMenu = ({ menuOptions, onItemClick, menuStyles, position, scroll, onClose }) => {
  const close = () => {
    const target = getDomElement()
    if (target) {
      unmountComponentAtNode(target)
      target.parentNode.removeChild(target)
    }
    onClose && onClose()
  }
  const onMouseDown = (e) => {
    let isMenu = e.target.closest('.menu-option')
    if(!isMenu)
    {
      close()
    }
  }
  const keyboardClose = event => event.keyCode === 27 && close()
  useEffect(() => {
    document.addEventListener('keydown', keyboardClose, false)
    setTimeout(() => {
      if(getDomElement())
      {
        window.addEventListener("mousedown", onMouseDown);
      }
    }, 100)
    return () => {
      document.removeEventListener('keydown', keyboardClose, false)
      window.removeEventListener("mousedown", onMouseDown);
    }
  }, [])
  useEffect(() => {
    const target = getDomElement().firstChild

    let top = position.top
    if(scroll){
      top = scroll && (top + window.scrollY)
    }
    if(position.top + target.offsetHeight > window.innerHeight)
    {
      top -= (20 + position.top + target.offsetHeight) - window.innerHeight
    }
    target.style.top = top + 24 +  "px"
    target.style.left = (position.left + 24 - target.offsetWidth) + "px"
  }, [position])

  return (
    <OptionsMenu
      onItemClick={(id, s_id) => {
        onItemClick(id, s_id)
        close();
      }}
      menuOptions={!!menuOptions && menuOptions }
      positionStyles={menuStyles} />
  )
}
